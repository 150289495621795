import { useEffect, useState } from "react";
import { Autocomplete, Button, Grid } from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import bag_icon from "assets/icons/bag.png";
import SERVICES from "services";
import { useStyles } from "./styles";
import { matchSorter } from "match-sorter";

const ContentForm = ({
  form,
  addContent,
  deleteContent,
  handleChange,
  availableWeight,
  availablePrice,
  grossWeightStatus,
  grossPriceStatus,
  disabled,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    SERVICES.FILTERS.getParcelContentTypes().then((res) => {
      const newArray = [...res.data];
      const index = newArray.findIndex((el) => el.id === "9");
      if (index !== -1) {
        const element = newArray[index];
        newArray.splice(index, 1);
        newArray.splice(0, 0, element);
      }
      setOptions(newArray);
    });
  }, []);

  const filterOptions = (options, { inputValue }) => {
    const newOptions = options.map((el) => ({
      ...el,
      meta_key: el.meta_key?.split(",") || [],
    }));

    return matchSorter(newOptions, inputValue, {
      threshold: matchSorter.rankings.CONTAINS,
      keys: ["code", "name", "hy", "ru", "en", "latin", "meta_key"],
      sorter: (items) => items,
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            <img src={bag_icon} alt="icon" className={classes.icon} />
            <span>Contents</span>
          </div>
        </div>
      </Grid>
      {form.map((el, index) => (
        <Grid item xs={12} key={index}>
          <Grid container rowSpacing={0.5} columnSpacing={2}>
            <Grid item xs={12} className={classes.contentTitleContainer}>
              <div className={classes.contentTitle}>Content {index + 1}</div>
              {index !== 0 && !disabled && (
                <Button
                  variant="outlined"
                  className={classes.removeBtn}
                  onClick={(event) => deleteContent(event, index)}
                >
                  Remove content
                </Button>
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={classes.label}>
                Quantity <span className={classes.asterisk}>*</span>
              </div>
              <TextValidator
                size="small"
                fullWidth
                name="quantity"
                value={el.quantity}
                onChange={(event) => handleChange(event, index)}
                validators={["required"]}
                errorMessages={["Required field."]}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={classes.label}>
                Content <span className={classes.asterisk}>*</span>
              </div>
              <Autocomplete
                disablePortal
                options={options}
                getOptionLabel={(option) => {
                  if (option === null) return option;

                  if (typeof option === "object") {
                    return option.name;
                  }
                  const opt = options?.find((el) => el.id === option);
                  return opt?.name || "";
                }}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value;
                }}
                filterOptions={filterOptions}
                value={el.content_id}
                onChange={(event, option) => {
                  const newValue = option?.id;
                  handleChange(
                    {
                      target: { name: "content_id", value: newValue || null },
                    },
                    index
                  );
                  handleChange(
                    {
                      target: { name: "other_name", value: "" },
                    },
                    index
                  );
                }}
                disabled={disabled}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    value={el.content_id}
                    size="small"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "nope",
                    }}
                    validators={["required"]}
                    errorMessages={["Required field."]}
                  />
                )}
              />
            </Grid>
            {el.content_id === "9" && (
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>
                  Other content <span className={classes.asterisk}>*</span>
                </div>
                <TextValidator
                  size="small"
                  fullWidth
                  name="other_name"
                  value={el.other_name}
                  onChange={(event) => handleChange(event, index)}
                  validators={["required"]}
                  errorMessages={["Required field."]}
                  disabled={disabled}
                />
              </Grid>
            )}
            <Grid item xs={12} lg={6}>
              <div className={classes.label}>
                Gross kg <span className={classes.asterisk}>*</span>
                <span
                  style={{
                    color: grossWeightStatus ? "green" : "red",
                    marginLeft: 10,
                  }}
                >
                  {availableWeight.toFixed(2)}
                </span>
              </div>
              <TextValidator
                size="small"
                fullWidth
                name="weight"
                value={el.weight}
                onChange={(event) => handleChange(event, index)}
                validators={["required"]}
                errorMessages={["Required field."]}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={classes.label}>
                Value (euro) <span className={classes.asterisk}>*</span>
                <span
                  style={{
                    color: grossPriceStatus ? "green" : "red",
                    marginLeft: 10,
                  }}
                >
                  {availablePrice.toFixed(2)}
                </span>
              </div>
              <TextValidator
                size="small"
                fullWidth
                name="price"
                value={el.price}
                onChange={(event) => handleChange(event, index)}
                validators={["required"]}
                errorMessages={["Required field."]}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
      {!disabled && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            className={classes.addBtn}
            onClick={addContent}
          >
            Add content
          </Button>
        </Grid>
      )}
    </>
  );
};

export default ContentForm;
