import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import logo from "assets/logo.png";
import Barcode from "react-barcode";
import SERVICES from "services";
import SenderReciver from "./componentsForPrint/SenderReciver";
import Form1PackageInfo from "./componentsForPrint/Form1PackageInfo";
import SenderRecieverForm2 from "./componentsForPrint/SenderRecieverForm2";
import Form2PackageInfo from "./componentsForPrint/Form2PackageInfo";
import PublicIcon from "@mui/icons-material/Public";
import Form1RightSection from "./componentsForPrint/Form1RightSection";
import moment from "moment";
import { Divider } from "@mui/material";
import { AuthContext } from "context/Auth.context";

const useStyeles = makeStyles((theme) => ({
  wrapper: {
    padding: "10px 20px 0 20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  form1: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  form1Header: {
    display: "flex",
    justifyContent: "space-between",
    height: 80,
    width: "100%",
    marginBottom: 5,
  },
  form2Header: {
    display: "flex",
    justifyContent: "space-between",
    height: 70,
    width: "100%",
  },
  form1LeftSection: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
  },
  form1CenterSection: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 10,
    fontWeight: 900,
    fontSize: 32,
    opacity: 0.5,
  },
  packageText: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: "left",
  },
  headBarCode: {
    height: 60,
    textAlign: "left",
    boxSizing: "border-box",
  },
  officeText: { textAlign: "left", fontSize: 12, fontWeight: 400 },
  form1RightSection: {
    height: "100%",
    justifyContent: "space-between",
    alignItems: "self-end",
    alignContent: "flex-end",
    display: "flex",
    // position: "relative",
    textAlign: "right",
    flexDirection: "column",
  },
  imgLogo: {
    width: 90,
    // position: "absolute",
    // bottom: 0,
    // right: 0,
  },
  form2: { color: "#888" },
  section3: { display: "flex", height: "max-content" },
}));

const ComponentToPrint = ({ listId, id }) => {
  const classes = useStyeles();
  const { state: profile } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const name =
    profile.firstname[0].toUpperCase() + profile.lastname[0].toUpperCase();

  const [tracking_number, setTrackingNumber] = useState("");

  useEffect(() => {
    SERVICES.ORDERS.getParcelById(listId, id).then((res) => {
      if (res.data.length) {
        setData(res.data[0]);
        setTrackingNumber(
          res.data[0].tracking_code + res.data[0].tracking_number
        );
      }
    });
  }, [id, listId]);
  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.form1}>
          <div className={classes.form1Header}>
            <div className={classes.form1LeftSection}>
              <div className={classes.headBarCode}>
                {!!tracking_number && (
                  <Barcode
                    value={tracking_number}
                    displayValue={false}
                    height={35}
                  />
                )}
              </div>
              <div className={classes.officeText}>
                Office of origin/Գրասենյակ։ {name}
              </div>
              <div className={classes.packageText}>Package and parcel form</div>
            </div>
            <div className={classes.form1CenterSection}>
              {data?.sender?.country_code === "AM"
                ? `[ ${data?.receiver?.country_code} / ${
                    data?.receiver?.country_code === "AM"
                      ? data?.receiver?.states_name
                      : data?.receiver?.other_states_name
                  } ]`
                : data?.parcel_local_delivery === "1" &&
                  `[ ${
                    data?.receiver?.country_code === "AM"
                      ? data?.receiver?.states_name
                      : data?.receiver?.other_states_name
                  } ]`}
              {data?.sender?.taxpayer_id === "1" && " / Legal"}
            </div>
            <div className={classes.form1RightSection}>
              <img src={logo} alt="" className={classes.imgLogo} />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "38%" }}>
              <SenderReciver data={data} />
            </div>
            <div style={{ width: "59%" }}>
              <Form1RightSection
                data={data}
                tracking_number={tracking_number}
              />
            </div>
          </div>
          <div className={classes.section3}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                fontSize: 9,
                paddingBottom: 5,
                marginTop: 3,
                borderBottom: "2px solid #444",
                color: "#777",
                textAlign: "start",
                width: "100%",
                height: "max-content",
                alignItems: "start",
              }}
            >
              <div style={{ fontSize: 8, lineHeight: "7px" }}>
                By signing this paper, I confirm that in the parcel, there is no
                packaged or unpackaged tobacco, narcotics, weapons, explosive or
                combustible materials, items of ethnic value and all the other
                not allowed items mentioned in the terms and conditions of
                <span style={{ fontWeight: 600 }}> BAREKAM POLAND</span> (
                <i>www.barekam.am/Terms-EN.pdf</i>) and I'm acquainted with the
                terms and conditions of{" "}
                <span style={{ fontWeight: 600 }}>BAREKAM POLAND</span> (in
                written and/or verbal forms).
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  fontSize: 11,
                  color: "#777",
                }}
              >
                <div>Date: {moment().format("YYYY - MM - DD")}</div>
                <div>Signature: </div>
                <div
                  style={{
                    fontSize: 25,
                    fontWeight: 600,
                    marginRight: 10,
                  }}
                >
                  {tracking_number}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.form2}>
          <div className={classes.form2Header}>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                justifyContent: "end",
                paddingBottom: 5,
              }}
            >
              <div className={classes.packageText}>
                Tracking No / Բեռնային համար: {tracking_number}
              </div>
              <div className={classes.packageText}>
                Office of origin/Գրասենյակ: {name}
              </div>
              <div className={classes.packageText}>
                Package and parcel form / Confirmation of sending a copy
              </div>
            </div>

            <div className={classes.form1RightSection}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PublicIcon />
                www.barekam.am
              </div>
              <img src={logo} alt="" className={classes.imgLogo} />
            </div>
          </div>
          <SenderRecieverForm2 data={data} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              fontSize: 9,
              marginTop: 4,
              borderBottom: "2px solid #888",
              paddingBottom: 5,
              textAlign: "start",
              width: "100%",
              height: "max-content",
              alignItems: "start",
            }}
          >
            <div style={{ fontSize: 8, lineHeight: "7px" }}>
              By signing this paper, I confirm that in the parcel, there is no
              packaged or unpackaged tobacco, narcotics, weapons, explosive or
              combustible materials, items of ethnic value and all the other not
              allowed items mentioned in the terms and conditions of
              <span style={{ fontWeight: 600 }}> BAREKAM POLAND</span> (
              <i>www.barekam.am/Terms-EN.pdf</i>) and I'm acquainted with the
              terms and conditions of{" "}
              <span style={{ fontWeight: 600 }}>BAREKAM POLAND</span> (in
              written and/or verbal forms).
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: 5,
                alignItems: "end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  minHeight: 55,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    whiteSpace: "pre",
                  }}
                >
                  BAREKAM SENDER ID: {data?.sender?.country_code}
                  {data?.sender.contract_id} {"\n"}
                  BAREKAM RECEIVER ID: {data?.receiver?.country_code}
                  {data?.receiver.contract_id}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Date: {moment().format("YYYY-MM-DD")}</div>
                </div>
              </div>

              <div
                style={{
                  fontSize: 11,
                  fontWeight: 400,
                  marginRight: 5,
                  minHeight: 55,
                }}
              >
                {data?.payment_bank_transfer &&
                  data?.payment_bank_transfer > 0 && (
                    <>
                      <div>
                        <span style={{ fontWeight: 600 }}>Company: </span> BLS
                        POLAND SP. Z.O.O
                      </div>
                      <div>
                        <span style={{ fontWeight: 600 }}>
                          Bank account number:{" "}
                        </span>
                        {data.sender.country_id === "179" ||
                        data.receiver.country_id === "179"
                          ? "Acc. Zloty: PL66 1090 0088 0000 0001 5287 8299"
                          : "Acc. EURO: PL31 1090 0088 0000 0001 5287 8347"}
                      </div>
                      <div>
                        <span style={{ fontWeight: 600 }}>Number BIC: </span>{" "}
                        WBKPPLPP
                      </div>
                      <div>
                        <span style={{ fontWeight: 600 }}>
                          Transfer amount:{" "}
                        </span>
                        {data?.payment_bank_transfer} €
                      </div>
                      <div>
                        <span style={{ fontWeight: 600 }}>Title: </span> Package
                        -{tracking_number}
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
          {/* <Form2PackageInfo data={data} /> */}
        </div>
      </div>
    </div>
  );
};

export default ComponentToPrint;
