import { Button, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./styles";
import { LIST_TYPES } from "../Lists";
import truck_icon from "assets/truck.png";
import eye_icon from "assets/icons/eye.png";
import { useState } from "react";
import moment from "moment";
import SERVICES from "services";

const List = ({ list, listType, isMobile }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [couriers, setCouriers] = useState([]);

  const getListCouriers = (id) => {
    SERVICES.ORDERS.getOrderCouriers(id).then((res) => {
      setCouriers(res.data);
    });
  };

  const handleClick = (event, id) => {
    getListCouriers(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={classes.container}
      style={{
        border:
          listType === LIST_TYPES.openLists
            ? "1px solid #FCB331"
            : "1px solid #D9534F",
      }}
    >
      <div className={classes.topSection}>
        <div className={classes.direction}>
          <span className={classes.data}>{list.name}</span>
          <br />
          <span className={classes.data}>
            {!list.from_countries_name && !list.to_countries_name
              ? ""
              : "| " +
                (list.from_countries_name || "") +
                " - " +
                (list.to_countries_name || "")}
          </span>
        </div>
        <div>
          <Button
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => handleClick(event, list.id)}
          >
            <img src={eye_icon} alt="eye_icon" className={classes.eye} />
          </Button>
          <Menu
            disableScrollLock={true}
            className={classes.menu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <span className={classes.viewers}>Responsible</span>
            <MenuItem onClick={handleClose}>
              {list.firstname} {list.lastname}
            </MenuItem>
            <span className={classes.viewers}>Viewers</span>
            {couriers.map((el) => (
              <MenuItem key={el.id} onClick={handleClose}>
                {el.firstname + " " + el.lastname}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      <div className={classes.mainInfo}>
        <div>
          <div className={classes.eachLine}>
            <span className={classes.infoTitile}>Total weight</span>
            <span className={classes.data}>{list.total_weight || 0} kg</span>
          </div>
          <div className={classes.eachLine}>
            <span className={classes.infoTitile}>List number</span>
            <span className={classes.data}>{list.list_number}</span>
          </div>
          <div className={classes.eachLine}>
            <span className={classes.infoTitile}>Created date</span>
            <span className={classes.data}>
              {moment(list.date_added).format("YYYY-MM-DD")}
            </span>
          </div>
        </div>

        {!isMobile && (
          <div>
            <img src={truck_icon} alt="truck_icon" />
          </div>
        )}
      </div>
      <div className={classes.btnSection}>
        <Button
          variant="contained"
          className={classes.viewBtn}
          onClick={() => navigate(`/parcels/${list.id}`)}
        >
          View
        </Button>
        {listType === LIST_TYPES.openLists && (
          <Button
            variant="contained"
            className={classes.btn}
            onClick={() => navigate(`/parcelReservation/${list.id}`)}
          >
            Reservation
          </Button>
        )}
      </div>
    </div>
  );
};

export default List;
