import axiosClient from "../utils/axiosClient";
import api from "../api";

const login = async (data) => {
  const response = await axiosClient.post(api.AUTH.LOGIN, data);
  return response.data;
};

const changePass = async (data) => {
  const response = await axiosClient.post(api.AUTH.CHANGE_PASS, data);
  return response.data;
};

const service = {
  login,
  changePass,
};

export default service;
