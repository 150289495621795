import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 20,
    fontWeight: 600,
    fontSize: 40,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },
  subTitle: {
    fontWeight: 400,
    fontSize: 18,
    paddingBottom: 15,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
}));

const SectionTitle = ({ title, subTitle }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.title}>{title}</div>
      {!!subTitle && <div className={classes.subTitle}>{subTitle}</div>}
    </>
  );
};

export default SectionTitle;
