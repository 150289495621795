import Lists from "pages/Lists";
import ParcelReservation from "pages/ParcelReservation";
import Footer from "./components/layout/Footer";
import NavBar from "./components/layout/NavBar";
import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import WarningContainer from "./components/WarningContainer";
import { AuthContext } from "./context/Auth.context";
import Toast from "./components/Toast";
import { Container } from "@mui/material";
import Parcels from "pages/Parcels";
import Profile from "pages/Profile";
import CostHistory from "pages/CostHistory";
import Loading from "components/Loading";
import Landing from "pages/Landing";
import "./App.css";

const PrivateRoute = ({ isAuthenticated, redirectPath = "/", children }) => {
  if (!isAuthenticated && !localStorage.getItem("token")) {
    return <Navigate to={redirectPath} />;
  }

  return children;
};

function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
}

const App = () => {
  const { state: authState } = useContext(AuthContext);
  const { pathname } = useLocation();

  return (
    <div className="App">
      <NavBar />
      <Container
        maxWidth={pathname !== "/" ? "xl" : false}
        style={pathname !== "/" ? { margin: "30px auto 80px" } : { padding: 0 }}
      >
        <ScrollToTop>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/tracking/:trackNumber" element={<Landing />} />
              <Route
                path="/lists"
                element={
                  <PrivateRoute isAuthenticated={authState.isAuthenticated}>
                    <Lists />
                  </PrivateRoute>
                }
              />
              <Route
                path="/courierProfile"
                element={
                  <PrivateRoute isAuthenticated={authState.isAuthenticated}>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/costHistory"
                element={
                  <PrivateRoute isAuthenticated={authState.isAuthenticated}>
                    <CostHistory />
                  </PrivateRoute>
                }
              />
              <Route
                path="/parcelReservation/:id"
                element={
                  <PrivateRoute isAuthenticated={authState.isAuthenticated}>
                    <ParcelReservation />
                  </PrivateRoute>
                }
              />
              <Route
                path="/parcelReservation/:id/:parcelId"
                element={
                  <PrivateRoute isAuthenticated={authState.isAuthenticated}>
                    <ParcelReservation />
                  </PrivateRoute>
                }
              />
              <Route
                path="/parcelReservation/:id/:parcelId/:warehouseId"
                element={
                  <PrivateRoute isAuthenticated={authState.isAuthenticated}>
                    <ParcelReservation />
                  </PrivateRoute>
                }
              />
              <Route
                path="/parcels/:id"
                element={
                  <PrivateRoute isAuthenticated={authState.isAuthenticated}>
                    <Parcels />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<WarningContainer />} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Container>
      <Footer />
      <Toast />
    </div>
  );
};

export default App;
