import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  blocks: {
    border: "1px solid #A7A7A7",
    borderRadius: 12,
    padding: 10,
    marginBottom: 15,
  },
  blockTitle: {
    fontWeight: 600,
    fontSize: 22,
    textAlign: "center",
    marginBottom: 8,
  },
  val: {
    fontSize: 18,
  },
  eachBlock: {
    display: "flex",
    justifyContent: "space-between",
    padding: "3px 25% 3px 40px",
    borderRadius: 12,
    alignItems: "center",
    marginBottom: 10,
    cursor: "pointer",
    "&:hover": {
      background: "#f4f4f4",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 5px",
    },
  },
  warehouse: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    paddingLeft: 50,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  infoTitle: {
    color: "rgba(38, 52, 31, 0.8)",
  },
  infoText: {
    fontSize: 17,
    fontWeight: 600,
    marginLeft: 10,
  },
  search: {
    margin: "20px 0px",
    display: "flex",
    width: "100% !important",
    border: "1px solid #13A800",
    borderRadius: "12px  !important",
    "&.MuiPaper-root": {
      boxShadow: "unset",
      boxSizing: "border-box",
    },
    "& .MuiIconButton-root": {
      color: "#A7A7A7",
    },
    [theme.breakpoints.down("sm")]: {
      height: 90,
    },
  },
  selectBlock: {
    display: "flex",
    position: "absolute",
    right: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "50%",
    },
  },
  citiesForm: {
    "& .MuiInputBase-root ": {
      background: "#F7F7F7",
      height: 35,
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover": {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  selectTitle: {
    fontSize: 18,
    color: "#A7A7A7",
  },
  totalBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  checkbox: {
    "&.MuiCheckbox-root.Mui-checked, &.MuiCheckbox-root.MuiCheckbox-indeterminate":
      {
        color: theme.palette.primary.main,
      },
  },
  total: {
    display: "flex",
    padding: "10px 20px",
    background: "#FCB331",
    borderRadius: 12,
    width: "fit-content",
    color: "#fff",
    [theme.breakpoints.down("lg")]: {
      marginBottom: 10,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  eachTotal: {
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
    margin: "0px 10px",
    fontSize: 16,
  },
  divider: {
    "&.MuiDivider-root": {
      borderColor: "#fff",
    },
  },
  pagination: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
      marginTop: 50,
    },
    "& .MuiButtonBase-root": {
      borderRadius: 6,
      fontSize: 12,
      fontWeight: 600,
      borderColor: "#BCBCBC",
    },
    "& .MuiButtonBase-root.Mui-selected": {
      color: "#fff",
      background: theme.palette.primary.main,
      border: "none",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    "& .MuiPaginationItem-icon": {
      color: theme.palette.primary.main,
    },
  },
  btn: {
    maxWidth: 170,
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 8,
      fontWeight: 700,
      fontSize: 16,
      marginLeft: 10,
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.primary.main,
      },
      "& .Mui-disabled": {
        color: "rgba(0, 0, 0, 0.26)",
        background: "rgba(0, 0, 0, 0.12)",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "unset",
        marginBottom: 5,
        marginLeft: 0,
      },
    },
  },
}));
