import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import bag_icon from "assets/icons/bag.png";
import delivery_icon from "assets/icons/delivery_icon.png";
import { useStyles } from "./styles";
import SERVICES from "services";

const InternalDelivery = ({ form, handleChange, disabled, customerId }) => {
  const classes = useStyles();
  const [states, setStates] = useState([]);
  const [recipientAddress, setRecipientAddress] = useState({
    country_code: "",
    address: "",
    states_id: null,
  });

  const localDeliveryAddress = localStorage.getItem("localDeliveryAddress");
  const localDeliveryStatesId = localStorage.getItem("localDeliveryStatesId");
  const localDeliveryAddressCount = localStorage.getItem(
    "localDeliveryAddressCount"
  );

  let intDeliveryPayment =
    form.deliveryType === 1 && form.local_delivery_states_id
      ? form.local_delivery_states_id === "1"
        ? 5
        : 7
      : 0;

  const isSameAddress =
    localDeliveryAddress &&
    localDeliveryAddress === form.local_delivery_full_address &&
    localDeliveryStatesId === form.local_delivery_states_id;

  if (isSameAddress) {
    if (localDeliveryAddressCount === "1") {
      intDeliveryPayment = 0;
    } else if (localDeliveryAddressCount > 1) {
      intDeliveryPayment = 1;
    }
  }

  useEffect(() => {
    SERVICES.FILTERS.getStates().then((res) => {
      setStates(res.data);
    });
  }, []);

  useEffect(() => {
    if (customerId) {
      SERVICES.CUSTOMERS.getCustomers({ customer_id: customerId }).then(
        (res) => {
          const customer = res.data[0];
          setRecipientAddress({
            address: customer.customer_address,
            states_id: customer.states_id,
            country_code: customer.country_code,
          });
        }
      );
    } else {
      setRecipientAddress({
        country_code: "",
        address: "",
        states_id: null,
      });
    }
  }, [customerId]);

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            <img src={delivery_icon} alt="icon" className={classes.icon} />
            <span>Internal delivery</span>
          </div>
          {!disabled && (
            <Button
              variant="outlined"
              className={
                form.parcel_local_delivery === "1"
                  ? classes.removeBtn
                  : classes.btn
              }
              onClick={() => {
                handleChange({
                  target: {
                    name: "parcel_local_delivery",
                    value: form.parcel_local_delivery === "1" ? "2" : "1",
                  },
                });
              }}
            >
              {form.parcel_local_delivery === "1" ? "Remove" : "Add"}
            </Button>
          )}
        </div>
      </Grid>
      {form.parcel_local_delivery === "1" && (
        <>
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              Delivery province <span className={classes.asterisk}>*</span>
            </div>
            <TextValidator
              select
              size="small"
              fullWidth
              name="local_delivery_states_id"
              value={form.local_delivery_states_id}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={["Required field."]}
              disabled={disabled}
            >
              {states.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </TextValidator>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              Full address <span className={classes.asterisk}>*</span>
            </div>
            <TextValidator
              size="small"
              fullWidth
              name="local_delivery_full_address"
              value={form.local_delivery_full_address}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={["Required field."]}
              disabled={disabled}
            />
            {/* <Button
              variant="outlined"
              className={classes.btn}
              onClick={() => {}}
            >
              Use the current recipient address
            </Button> */}
            {!!localDeliveryAddress && !disabled && (
              <Card
                sx={{ maxWidth: 275, marginTop: "15px" }}
                className={classes.addressCard}
              >
                <CardContent style={{ paddingBottom: 0 }}>
                  <Typography
                    sx={{ fontSize: 17, fontWeight: "bold" }}
                    gutterBottom
                  >
                    <div> {localDeliveryAddress}</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: 20,
                        }}
                      >
                        {localDeliveryAddressCount}{" "}
                        <img src={bag_icon} alt="icon" />
                      </div>
                      <div> {intDeliveryPayment} €</div>
                    </div>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => {
                      handleChange({
                        target: {
                          name: "local_delivery_full_address",
                          value: localDeliveryAddress,
                        },
                      });
                      handleChange({
                        target: {
                          name: "local_delivery_states_id",
                          value: localDeliveryStatesId,
                        },
                      });
                    }}
                  >
                    Use the last entered address
                  </Button>
                </CardActions>
              </Card>
            )}
            {recipientAddress.country_code === "AM" && !disabled && (
              <Card
                sx={{ maxWidth: 275, marginTop: "15px" }}
                className={classes.addressCard}
              >
                <CardContent style={{ paddingBottom: 0 }}>
                  <Typography
                    sx={{ fontSize: 17, fontWeight: "bold" }}
                    gutterBottom
                  >
                    <div> {recipientAddress.address}</div>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => {
                      handleChange({
                        target: {
                          name: "local_delivery_full_address",
                          value: recipientAddress.address,
                        },
                      });
                      handleChange({
                        target: {
                          name: "local_delivery_states_id",
                          value: recipientAddress.states_id,
                        },
                      });
                    }}
                  >
                    Use recipient address
                  </Button>
                </CardActions>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              Internal delivery type <span className={classes.asterisk}>*</span>
            </div>
            <Select
              size="small"
              fullWidth
              name="deliveryType"
              value={form.deliveryType}
              onChange={handleChange}
              disabled={disabled}
            >
              <MenuItem value={1}>Standart</MenuItem>
              <MenuItem value={2}>Free</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              Internal delivery payment{" "}
              <span className={classes.asterisk}>*</span>
            </div>
            <Select size="small" fullWidth value="eur" disabled>
              <MenuItem value={"eur"}>EUR</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} style={{ fontWeight: 600, padding: "20px 0" }}>
            <div>Payment amount</div>
            <div style={{ fontSize: 22, marginTop: 10 }}>
              {form.local_delivery_price || intDeliveryPayment} €
            </div>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="warning">
              Price to Yerevan - 5 euro, includes 2 pieces of delivery to one
              address, each additional piece 1 euro <br />
              Cost for Regions - 7 euro, includes 2 pieces of delivery to one
              address, each additional piece 1 euro
            </Alert>
          </Grid>
        </>
      )}
    </>
  );
};

export default InternalDelivery;
