import {
  Container,
  Divider,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import down from "assets/icons/dropdownbtn.png";
import right from "assets/icons/arrowRightbtn.png";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SectionTitle from "components/SectionTitle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  fixedWidthContainer: {
    scrollMargin: "100px",
    margin: "100px auto",
    marginBottom: 0,
    [theme.breakpoints.down("md")]: {
      margin: "20px auto",
    },
  },
  container: {
    color: theme.palette.primary.contrastText,
  },
  bottomCont: {
    background: "#F7F7FB",
    padding: "100px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 0px",
    },
  },
  questionsContainer: {
    display: "flex",
    background: "#fff",
    borderRadius: 12,
    boxShadow: "0px 6px 16px rgba(74, 58, 255, 0.19)",
    [theme.breakpoints.down("md")]: {
      borderRadius: "0px 0px 12px 12px",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  gridContainer: {
    padding: 50,
    whiteSpace: "pre-line",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
    "& .MuiPaper-root": {
      background: "#FFFFFF",
      boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
      borderRadius: "12px !important",
      display: "flex",
      flexDirection: "column",
      // minHeight: 100,
      padding: "15px 0px",
      // paddingTop: 30,
    },
  },
  question: {
    display: "flex",
    textAlign: "left",
    fontWeight: 500,
    fontSize: 22,
    maxWidth: "fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  icon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  block: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  fromTo: {
    padding: "26px 130px",
    background: "#FFFFFF",
    // boxShadow: "0px 6px 16px rgba(74, 58, 255, 0.19)",
    borderRadius: "12px 12px 0px 0px",
    // width: "20%",
    margin: "0 auto",
    fontWeight: 700,
    fontSize: 26,
    color: "#13A800",
    // "&:hover": {
    //   cursor: "pointer",
    // },
    [theme.breakpoints.down("lg")]: {
      padding: "26px 70px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      width: "87%",
      padding: 20,
      fontSize: 20,
    },
  },
  answer: {
    display: "flex",
    textAlign: "left",
    paddingTop: 15,
    fontSize: 18,
    "& a": {
      display: "contents",
    },
  },
  accordion: {
    "& .MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0px 36px",
    },
    "& .MuiCollapse-root": {
      padding: "0px 36px",
    },
    "& .MuiAccordionDetails-root": {
      padding: "8px 16px 16px 0px",
    },
  },
}));
const data = (t) => [
  {
    question: t("questions.faq1Title"),
    answer: t("questions.faq1Description"),
  },
  {
    question: t("questions.faq2Title"),
    answer: t("questions.faq2Description"),
  },
  {
    question: t("questions.faq3Title"),
    answer: t("questions.faq3Description"),
  },
  {
    question: t("questions.faq4Title"),
    answer: t("questions.faq4Description"),
  },
  {
    question: t("questions.faq5Title"),
    answer: t("questions.faq5Description"),
  },
  {
    question: t("questions.faq6Title"),
    answer: t("questions.faq6Description"),
  },
  {
    question: t("questions.faq7Title"),
    answer: t("questions.faq7Description"),
  },
  {
    question: t("questions.faq8Title"),
    answer: t("questions.faq8Description"),
  },
  {
    question: t("questions.faq9Title"),
    answer: t("questions.faq9Description"),
  },
  {
    question: t("questions.faq10Title"),
    answer: t("questions.faq10Description"),
  },
  {
    question: t("questions.faq11Title"),
    answer: t("questions.faq11Description"),
  },
  {
    question: t("questions.faq12Title"),
    answer: t("questions.faq12Description"),
  },
];

const Questions = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  // const [open, setOpen] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [indexArr, setIndexArr] = useState([]);
  const [openBlock, setOpenBlock] = useState(1);

  const handleAccordionChange = (idx) => {
    setIndexArr((prev) => {
      const arr = [...prev];
      if (arr.indexOf(idx) === -1) {
        arr.push(idx);
      } else {
        arr.splice(arr.indexOf(idx), 1);
      }

      return arr;
    });
  };

  return (
    <Grid
      container
      spacing={2}
      className={classes.fixedWidthContainer}
      id="faq"
    >
      <Grid item xs={12}>
        <SectionTitle title={t("questions.title")} />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.bottomCont}>
          <Container maxWidth={"xl"}>
            <div className={classes.block}>
              <div
                className={classes.fromTo}
                style={{
                  background: openBlock === 1 ? "#13A800" : "#fff",
                  color: openBlock === 1 ? "#fff" : "#13A800",
                }}
              >
                {t("questions.parcels")}
              </div>
            </div>
            <div className={classes.questionsContainer}>
              <Grid container spacing={2} className={classes.gridContainer}>
                {data(t).map((each, idx) => (
                  <Grid item lg={6} xs={12} key={idx}>
                    <Accordion
                      className={classes.accordion}
                      onChange={() => handleAccordionChange(idx)}
                      style={{
                        border:
                          indexArr.indexOf(idx) !== -1
                            ? "1px solid #FCB331"
                            : "none",
                      }}
                    >
                      <AccordionSummary>
                        <span className={classes.question}>
                          {each.question}
                        </span>
                        <img
                          className={classes.icon}
                          style={{
                            maxHeight:
                              indexArr.indexOf(idx) === -1 && isMobile
                                ? 50
                                : 80,
                          }}
                          src={indexArr.indexOf(idx) === -1 ? right : down}
                          alt="arrow icon"
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <Divider />
                          <span
                            className={classes.answer}
                            dangerouslySetInnerHTML={{ __html: each.answer }}
                          ></span>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Container>
        </div>
      </Grid>
    </Grid>
  );
};

export default Questions;
