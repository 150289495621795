import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import bestPrice from "assets/icons/bestprice.png";
import pricelist from "assets/icons/pricelist.png";
import { useCallback, useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import service from "services/actions.service";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    flexDirection: "column",
    background: "transparent",
    "& .MuiPaper-root": {
      borderRadius: 12,
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiTypography-root": {
      padding: 0,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    "&.MuiDialogContent-root": {
      padding: "45px 100px",
      [theme.breakpoints.down("lg")]: {
        padding: 20,
      },
    },
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  titleBlock: {
    paddingBottom: 40,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      alignItems: "baseline",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontWeight: 700,
    fontSize: 36,
    textAlign: "center",
    lineHeight: 2,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "start",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  closeBtn: {
    border: "2px solid",
    borderRadius: "50%",
    cursor: "pointer",
  },
  inputTitle: {
    fontWeight: 1000,
    lineHeight: 2,
    color: "#252733",
    fontSize: 16,
    letterSpacing: "0.4px",
  },
  autocomplete: {
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  gridCont: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  asterisk: {
    fontWeight: 700,
    letterSpacing: "0.4px",
    color: "#EF4444",
    // padding: 8,
  },
  postIndex: {
    "&.MuiFormControl-root": {
      marginBottom: 20,
      [theme.breakpoints.down("lg")]: {
        marginBottom: 10,
      },
    },
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& .MuiFormLabel-asterisk": {
      color: "#FF0B21",
      fontSize: 18,
      fontWeight: 700,
    },
  },
  form: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      marginLeft: 12,
      marginTop: 8,
      justifyContent: "space-between",
    },
    "& .MuiFormControlLabel-root": {
      border: "1px solid #CDCDCD",
      borderRadius: "12px",
      //   maxWidth: "223px",
      width: "49%",
      height: "56px",
      marginRight: 0,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "unset",
    },
    "& .MuiRadio-root.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  radioSelect: {
    "&.MuiFormLabel-root": {
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: "0.4px",
      color: theme.palette.primary.contrastText,
    },
    "& .MuiFormLabel-asterisk": {
      color: "#FF0B21",
      fontSize: 20,
      fontWeight: 700,
    },
  },
  required: {
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: "0.4px",
    [theme.breakpoints.down("md")]: {
      marginBottom: 20,
    },
  },

  priceListImage: {
    padding: "10px 40px",
    background: "linear-gradient(134.85deg, #A3FF97 1.75%, #93E689 100%)",
    borderRadius: "12px 0px 0px 12px",
  },
  btnSection: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 35,
    marginBottom: 35,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },

  leftSide: {
    background: "#FFFFFF",
    borderRadius: 12,
    //   padding: "15",
    filter: "drop-shadow(0px 24px 64px rgba(0, 0, 0, 0.04))",
    width: "50%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  bestPriceImage: {
    padding: "10px 20px",
    background: "linear-gradient(135deg, #FFBF4E 0%, #FED182 100%)",
    borderRadius: "12px 0px 0px 12px",
  },
  bottomSpan: {
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
    fontWeight: 700,
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  btn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 12,
      fontWeight: 600,
      fontSize: 18,
      padding: "5px 30px",
      width: "15%",
      [theme.breakpoints.down("md")]: {
        marginTop: 20,
        width: "100%",
      },
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  },
  bottom: {
    marginTop: 40,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  priceListSpan: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  del: {
    fontWeight: 400,
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
}));

const initialForm = {
  country_from_id: "",
  country_to_id: "",
  weight: "",
  length: "",
  height: "",
  width: "",
};

function getPrice(tarifs, weight) {
  if (weight <= Number(tarifs.tariff_1_up_to_weight)) {
    return +tarifs.tariff_1_price;
  } else if (
    weight > Number(tarifs.tariff_1_up_to_weight) &&
    weight < Number(tarifs.tariff_2_up_to_weight)
  ) {
    return weight * Number(tarifs.tariff_2_price);
  } else if (
    (!tarifs.tariff_3_up_to_weight &&
      tarifs.tariff_3_price &&
      weight >= Number(tarifs.tariff_2_up_to_weight)) ||
    weight >= Number(tarifs.tariff_2_up_to_weight) /* &&
      tarifs.tariff_3_up_to_weight &&
      weight < Number(tarifs.tariff_3_up_to_weight) */
  ) {
    return weight * Number(tarifs.tariff_3_price);
  }
  return 0;
}

const CalculatorDialog = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [priceList, setPriceList] = useState([]);
  const [fromCountries, setFromCountries] = useState([]);
  const [toCountries, setToCountries] = useState([]);

  const [form, setForm] = useState(initialForm);
  const [weightInfo, setWeightInfo] = useState(null);

  const handleFormChange = (val, name) => {
    setForm((data) => {
      return {
        ...data,
        ...{
          [name]: val,
        },
      };
    });
  };

  const handleClose = () => {
    setOpen(false);
    setForm(initialForm);
    setWeightInfo(null);
  };

  const handleSubmit = () => {
    let weight1;
    let price;
    let duration;

    const { height, width, length, weight } = form;
    const listItem = priceList.find(
      (el) =>
        el.country_from_id === form.country_from_id &&
        el.country_to_id === form.country_to_id
    );

    if (height && width && length) {
      weight1 = height * width * length;
      if (weight1 > 360000 && weight1 < 511999) {
        weight1 /= 7000;
      } else if (weight1 > 511999) {
        weight1 /= 8000;
      } else {
        weight1 /= 6000;
      }

      price = getPrice(
        listItem.tariffs,
        weight > weight1 ? weight : weight1
      ).toFixed(2);
    } else {
      price = getPrice(listItem.tariffs, weight).toFixed(2);
    }
    duration = listItem.duration;
    setWeightInfo({ weight, price, duration });
  };

  const getPriceList = useCallback(() => {
    service.getPriceList().then((res) => {
      setPriceList(
        res.data.map((el) => ({
          ...el,
          tariffs: {
            tariff_1_price: el.tariff_1_price,
            tariff_1_up_to_weight: el.tariff_1_up_to_weight,
            tariff_2_price: el.tariff_2_price,
            tariff_2_up_to_weight: el.tariff_2_up_to_weight,
            tariff_3_price: el.tariff_3_price,
            tariff_3_up_to_weight: el.tariff_3_up_to_weight,
          },
        }))
      );
      setFromCountries(() => {
        const options = res.data.map((el) => ({
          name: el.from_countries_name,
          value: el.country_from_id,
        }));
        return [
          ...new Map(options.map((item) => [item["name"], item])).values(),
        ];
      });
    });
  }, []);

  useEffect(() => {
    getPriceList();
  }, [getPriceList]);

  useEffect(() => {
    if (form.country_from_id) {
      setForm((data) => ({
        ...data,
        country_to_id: "",
      }));
      setToCountries(() => {
        const options = priceList
          .filter((el) => el.country_from_id === form.country_from_id)
          ?.map((el) => ({
            name: el.to_countries_name,
            value: el.country_to_id,
          }));

        return [
          ...new Map(options.map((item) => [item["name"], item])).values(),
        ];
      });
    }
  }, [form.country_from_id, priceList]);

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      className={classes.dialog}
    >
      <ValidatorForm
        onSubmit={handleSubmit}
        onError={(errors) => console.log(errors)}
      >
        <DialogContent className={classes.content}>
          <div className={classes.titleBlock}>
            <div className={classes.title}>{t("calcDidalog.title")}</div>
            <CloseIcon onClick={handleClose} className={classes.closeBtn} />
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <span className={classes.inputTitle}>
                {t("calcDidalog.sender")}{" "}
                <span className={classes.asterisk}>*</span>
              </span>
              <TextValidator
                select
                fullWidth
                name="country_from_id"
                value={form.country_from_id}
                onChange={(event) => {
                  handleFormChange(event.target.value, event.target.name);
                }}
                validators={["required"]}
                errorMessages={["Պարտադիր դաշտ"]}
              >
                {fromCountries.map((el) => (
                  <MenuItem key={el.value} value={el.value}>
                    {el.name}
                  </MenuItem>
                ))}
              </TextValidator>
            </Grid>
            <Grid item xs={12} lg={6}>
              <span className={classes.inputTitle}>
                {t("calcDidalog.recipient")}{" "}
                <span className={classes.asterisk}>*</span>
              </span>
              <TextValidator
                select
                fullWidth
                name="country_to_id"
                value={form.country_to_id}
                onChange={(event) => {
                  handleFormChange(event.target.value, event.target.name);
                }}
                validators={["required"]}
                errorMessages={["Պարտադիր դաշտ"]}
                disabled={!!!form.country_from_id}
              >
                {toCountries.map((el) => (
                  <MenuItem key={el.value} value={el.value}>
                    {el.name}
                  </MenuItem>
                ))}
              </TextValidator>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container className={classes.gridCont} spacing={2}>
                <Grid item lg={6} xs={12}>
                  <span className={classes.inputTitle}>
                    {t("calcDidalog.parcelWeight")}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <TextValidator
                    label=""
                    value={form.weight}
                    className={classes.postIndex}
                    fullWidth
                    onChange={(event) =>
                      !isNaN(Number(event.target.value)) &&
                      handleFormChange(event.target.value, "weight")
                    }
                    validators={["required"]}
                    errorMessages={["Պարտադիր դաշտ"]}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <span className={classes.inputTitle}>
                    {t("calcDidalog.parcelLength")}
                  </span>
                  <TextValidator
                    label=""
                    value={form.length}
                    className={classes.postIndex}
                    fullWidth
                    onChange={(event) =>
                      !isNaN(Number(event.target.value)) &&
                      handleFormChange(event.target.value, "length")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container className={classes.gridCont} spacing={2}>
                <Grid item lg={6} xs={12}>
                  <span className={classes.inputTitle}>
                    {t("calcDidalog.parcelHeight")}
                  </span>
                  <TextValidator
                    label=""
                    value={form.height || ""}
                    className={classes.postIndex}
                    fullWidth
                    onChange={(event) =>
                      !isNaN(Number(event.target.value)) &&
                      handleFormChange(Number(event.target.value), "height")
                    }
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <span className={classes.inputTitle}>
                    {t("calcDidalog.parcelWidth")}
                  </span>
                  <TextValidator
                    label=""
                    value={form.width}
                    className={classes.postIndex}
                    fullWidth
                    onChange={(event) =>
                      !isNaN(Number(event.target.value)) &&
                      handleFormChange(event.target.value, "width")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.btnSection}>
            <div className={classes.leftSide}>
              <img
                className={classes.bestPriceImage}
                src={bestPrice}
                alt="bestPrice"
              />
              <span className={classes.bottomSpan}>
                {t("calcDidalog.guaranteedBestPrice")}
              </span>
            </div>
            <Button className={classes.btn} variant="contained" type="submit">
              {t("calcDidalog.calculate")}
            </Button>
          </div>
          <Divider fullwidth />
          <div className={classes.bottom}>
            <div className={classes.required}>
              <span className={classes.asterisk}>*</span>
              {t("global.required")}
            </div>
            {weightInfo && (
              <div className={classes.leftSide}>
                <img
                  src={pricelist}
                  alt="pricelist"
                  className={classes.priceListImage}
                />
                <div className={classes.priceListSpan}>
                  <span className={classes.del}>
                    {t("calcDidalog.deliveryText", {
                      day: weightInfo.duration,
                    })}
                  </span>
                  <span className={classes.bottomSpan}>
                    {t("calcDidalog.totalPrice")} {weightInfo.weight}
                    {t("global.kg")} = {weightInfo.price}
                    {form.country_from_id === "7" ? "AMD" : "€"}
                  </span>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </ValidatorForm>
    </Dialog>
  );
};

export default CalculatorDialog;
