import axiosClient from "../utils/axiosClient";
import api from "../api";

const getCountries = async () => {
  const response = await axiosClient.get(api.FILTERS.COUNTRIES);
  return response.data;
};

const getStates = async () => {
  const response = await axiosClient.get(api.FILTERS.STATES_TYPES);
  return response.data;
};

const getPaymentTypes = async () => {
  const response = await axiosClient.get(api.FILTERS.PAYMENT_TYPES);
  return response.data;
};

const getParcelContentTypes = async () => {
  const response = await axiosClient.get(api.FILTERS.PARCEL_CONTENT_TYPES);
  return response.data;
};

const getCooperationTypes = async () => {
  const response = await axiosClient.get(api.FILTERS.COOPERATION_TYPES);
  return response.data;
};

const getCostTypes = async () => {
  const response = await axiosClient.get(api.FILTERS.COST_TYPES);
  return response.data;
};

const service = {
  getCountries,
  getPaymentTypes,
  getParcelContentTypes,
  getCooperationTypes,
  getStates,
  getCostTypes,
};

export default service;
