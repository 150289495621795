import AUTH from "./auth.service";
import ORDERS from "./orders.services";
import CUSTOMERS from "./customers.service";
import FILTERS from "./filters.service";
import ACTIONS from "./actions.service";
import WAREHOUSE from "./warehouse.service";
import COST from "./cost.service";

const SERVICES = {
  AUTH,
  ORDERS,
  CUSTOMERS,
  FILTERS,
  ACTIONS,
  WAREHOUSE,
  COST,
};

export default SERVICES;
