import { useContext } from "react";
import { Alert, Snackbar } from "@mui/material";
import { SettingsContext } from "context/Settings.context";

const Toast = () => {
  const { state, dispatch } = useContext(SettingsContext);

  const handleClose = () => {
    dispatch({
      type: "CLOSE_TOAST",
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={state.open}
      onClose={handleClose}
      autoHideDuration={5000}
    >
      <Alert
        onClose={handleClose}
        severity={state.severity}
        sx={{ width: "100%", minWidth: 400 }}
      >
        {state.text}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
