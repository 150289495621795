import {
  Container,
  Divider,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import down from "assets/icons/dropdownbtn.png";
import right from "assets/icons/arrowRightbtn.png";
import alcohol from "assets/icons/alcohol.png";
import chemicals from "assets/icons/chemicals.png";
import explosives from "assets/icons/explosives.png";
import nuclear from "assets/icons/nuclear.png";
import drugs from "assets/icons/drugs.png";
import psycho from "assets/icons/psycho.png";
import p from "assets/icons/18+.png";
import coffee from "assets/icons/coffee.png";
import tea from "assets/icons/tea.png";
import fruits from "assets/icons/fruits.png";
import sweets from "assets/icons/sweets.png";
import honey from "assets/icons/honey.png";
import spices from "assets/icons/spices.png";
import cans from "assets/icons/cans.png";
import perfume from "assets/icons/perfume.png";
import lithium from "assets/icons/lithium.png";
import other from "assets/icons/other.png";
import cigarette from "assets/icons/No_smoking.png";
import noMeat from "assets/icons/noMeat.png";
import noFish from "assets/icons/noFish.png";
import noMilk from "assets/icons/noMilk.png";
import noMoney from "assets/icons/noMoney.png";
import noFake from "assets/icons/noFake.png";
import noCorrespondence from "assets/icons/noCorrespondence.png";
import toys from "assets/icons/toys.png";
import clothes from "assets/icons/clothes.png";
import non from "assets/icons/non.png";

import { useState } from "react";
import SectionTitle from "components/SectionTitle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  fixedWidthContainer: {
    margin: "100px auto",
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("md")]: {
      margin: "20px auto",
    },
  },
  asterisk: {
    fontWeight: 600,
    letterSpacing: "0.4px",
    color: "#EF4444",
    // padding: 8,
  },
  bottomCont: {
    background: "#F7F7FB",
    padding: "100px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 0px",
    },
  },
  block: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  questionsContainer: {
    display: "flex",
    background: "#FFFFFF",
    boxShadow: "0px 6px 16px rgb(74 58 255 / 19%)",
    borderRadius: 12,
    height: "fit-content",
    padding: 50,
    [theme.breakpoints.down("md")]: {
      padding: 20,
      borderRadius: "0px 0px 12px 12px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  questionBox: {
    background: "#FFFFFF",
    boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    minHeight: 100,
    padding: "0px 35px",
    paddingTop: 30,
    [theme.breakpoints.down("md")]: {
      borderRadius: "0px 0px 12px 12px",
      padding: "30px 20px 0px",
    },
  },
  fromTo: {
    padding: "26px 100px",
    background: "#FFFFFF",
    // boxShadow: "0px 6px 16px rgba(74, 58, 255, 0.19)",
    borderRadius: "12px 12px 0px 0px",
    // width: "20%",
    margin: "0 auto",
    fontWeight: 600,
    fontSize: 26,
    color: "#13A800",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "26px 50px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      width: "87%",
      padding: 20,
      fontSize: 20,
    },
  },
  boxBlock: {
    display: "flex",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
    },
  },
  question: {
    fontWeight: 600,
    fontSize: "18px",
    maxWidth: 380,
    textAlign: "start",
  },
  arrowIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  openBlock: {
    padding: "10px 0px",
    textAlign: "start",
  },
  non: {
    display: "flex",
    paddingTop: 12,
  },
  icon: {
    paddingRight: 18,
    maxHeight: 28,
  },
  bottomSpan: {
    display: "flex",
    textAlign: "start",
    paddingTop: 30,
    color: "#374A59",
    fontSize: 14,
  },
}));

const data = (t) => [
  {
    // title: "Prohibited Items",
    title: t("prohibitedItems.bannedProducts"),

    info: [
      // { icon: alcohol, des: "Alcoholic beverages" },
      // { icon: pharma, des: "Pharmaceutical products" },
      // { icon: medicine, des: "Medicines" },

      // { icon: weapons, des: "Weapons and components thereof" },
      {
        icon: alcohol,
        des: t("prohibitedItems.exportBanned1"),
      },
      {
        icon: cigarette,
        des: t("prohibitedItems.exportBanned2"),
      },
      {
        icon: noMeat,
        des: t("prohibitedItems.exportBanned3"),
      },
      {
        icon: noFish,
        des: t("prohibitedItems.exportBanned4"),
      },
      {
        icon: noMilk,
        des: t("prohibitedItems.exportBanned5"),
      },
      {
        icon: noMoney,
        des: t("prohibitedItems.exportBanned6"),
      },
      {
        icon: lithium,
        des: t("prohibitedItems.exportBanned7"),
      },
      {
        icon: noFake,
        des: t("prohibitedItems.exportBanned8"),
      },
      {
        icon: p,
        des: t("prohibitedItems.exportBanned9"),
      },
      {
        icon: psycho,
        des: t("prohibitedItems.exportBanned10"),
      },
      {
        icon: drugs,
        des: t("prohibitedItems.exportBanned11"),
      },
      {
        icon: noCorrespondence,
        des: t("prohibitedItems.exportBanned12"),
      },
      {
        icon: explosives,
        des: t("prohibitedItems.exportBanned13"),
      },
      {
        icon: explosives,
        des: t("prohibitedItems.exportBanned14"),
      },
      { icon: nuclear, des: t("prohibitedItems.exportBanned15") },
      {
        icon: chemicals,
        des: t("prohibitedItems.exportBanned16"),
      },
      {
        icon: other,
        des: t("prohibitedItems.exportBanned17"),
      },
      {
        icon: other,
        des: t("prohibitedItems.exportBanned18"),
      },
    ],
  },
  {
    // title: "Restricted items",
    title: t("prohibitedItems.restrictedProducts"),

    info: [
      {
        icon: coffee,
        des: /*"Coffee: not more than 1 kg"*/ t(
          "prohibitedItems.exportRestricted1"
        ),
      },
      {
        icon: tea,
        des: /*"Tea: up to 0.5 kg"*/ t("prohibitedItems.exportRestricted2"),
      },
      {
        icon: fruits,
        des: /*"Dried fruits: up to 1 kg"*/ t(
          "prohibitedItems.exportRestricted3"
        ),
      },
      {
        icon: sweets,
        des: /* "Sweets: up to 1 kg" */ t("prohibitedItems.exportRestricted4"),
      },
      {
        icon: honey,
        des: /* "Honey: up to 0.5 kg"  */ t(
          "prohibitedItems.exportRestricted5"
        ),
      },
      {
        icon: spices,
        des: /* "Dried greens and spices: up to 1 kg" */ t(
          "prohibitedItems.exportRestricted6"
        ),
      },
      {
        icon: cans,
        des: /* "Cans with factory packaging: up to 3 kg" */ t(
          "prohibitedItems.exportRestricted7"
        ),
      },
      {
        icon: perfume,
        des: /* "Perfume: 1 bottle" */ t("prohibitedItems.exportRestricted8"),
      },
    ],
  },
];
const data2 = (t) => [
  {
    title: t("prohibitedItems.bannedProducts"),
    info: [
      {
        icon: cigarette,
        des: t("prohibitedItems.importBanned1"),
      },
      {
        icon: noMeat,
        des: t("prohibitedItems.importBanned2"),
      },
      {
        icon: noFish,
        des: t("prohibitedItems.importBanned3"),
      },
      {
        icon: noMilk,
        des: t("prohibitedItems.importBanned4"),
      },
      {
        icon: noMoney,
        des: t("prohibitedItems.importBanned5"),
      },
      {
        icon: lithium,
        des: t("prohibitedItems.importBanned6"),
      },
      {
        icon: noFake,
        des: t("prohibitedItems.importBanned7"),
      },
      {
        icon: p,
        des: t("prohibitedItems.importBanned8") /*"Pornography"  */,
      },
      {
        icon: psycho,
        des: t("prohibitedItems.importBanned9"),
      },
      {
        icon: drugs,
        des: t("prohibitedItems.importBanned10"),
      },
      {
        icon: noCorrespondence,
        des: t("prohibitedItems.importBanned11"),
      },
      {
        icon: explosives,
        des: t("prohibitedItems.importBanned12"),
      },
      {
        icon: explosives,
        des: t("prohibitedItems.importBanned13"),
      },
      { icon: nuclear, des: t("prohibitedItems.importBanned14") },
      {
        icon: chemicals,
        des: t("prohibitedItems.importBanned15"),
      },
      {
        icon: other,
        des: t("prohibitedItems.importBanned16"),
      },
      {
        icon: other,
        des: t("prohibitedItems.importBanned17"),
      },
    ],
  },
  {
    title: t("prohibitedItems.restrictedProducts"),
    info: [
      // { icon: coffee, des: "Coffee: not more than 1 kg" },
      // { icon: tea, des: "Tea: up to 0.5 kg" },
      // { icon: fruits, des: "Dried fruits: up to 1 kg" },
      // { icon: sweets, des: "Sweets: up to 1 kg" },

      {
        icon: coffee,
        des: t("prohibitedItems.importRestricted1"),
      },
      // { icon: tea, des:"Թեյ- մինչև 0․5 կգ" },
      {
        icon: sweets,
        des: t("prohibitedItems.importRestricted2"),
      },
      { icon: fruits, des: t("prohibitedItems.importRestricted3") },
      { icon: honey, des: t("prohibitedItems.importRestricted4") },
      {
        icon: spices,
        des: t("prohibitedItems.importRestricted5"),
      },
      {
        icon: cans,
        des: t("prohibitedItems.importRestricted6"),
      },
      {
        icon: non,
        des: t("prohibitedItems.importRestricted7"),
      },
      {
        icon: clothes,
        des: t("prohibitedItems.importRestricted8"),
      },
      {
        icon: clothes,
        des: t("prohibitedItems.importRestricted9"),
      },
      {
        icon: non,
        des: t("prohibitedItems.importRestricted10"),
      },
      {
        icon: non,
        des: t("prohibitedItems.importRestricted11"),
      },
      {
        icon: non,
        des: t("prohibitedItems.importRestricted12"),
      },
      {
        icon: non,
        des: t("prohibitedItems.importRestricted13"),
      },
      {
        icon: perfume,
        des: t("prohibitedItems.importRestricted14"),
      },
      {
        icon: non,
        des: t("prohibitedItems.importRestricted15"),
      },
      {
        icon: toys,
        des: t("prohibitedItems.importRestricted16"),
      },
      {
        icon: non,
        des: t("prohibitedItems.importRestricted17"),
      },
    ],
  },
];

const ProhibitedItems = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openProhibited, setOpenProhibited] = useState(0);
  const [openRestricted, setOpenRestricted] = useState(1);
  const [openBlock, setOpenBlock] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const allData = openBlock === 1 ? data(t) : data2(t);
  return (
    <Grid
      container
      spacing={2}
      className={classes.fixedWidthContainer}
      id="prohibited"
    >
      <Grid item xs={12}>
        <SectionTitle
          title={t("prohibitedItems.title")}
          subTitle={
            <>
              {t("prohibitedItems.subTitle")}{" "}
              <span className={classes.asterisk}>*</span>
            </>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.bottomCont}>
          <Container maxWidth={"xl"}>
            <div className={classes.block}>
              <div
                className={classes.fromTo}
                style={{
                  background: openBlock === 1 ? "#13A800" : "#fff",
                  color: openBlock === 1 ? "#fff" : "#13A800",
                }}
                onClick={() => {
                  setOpenBlock(1);
                }}
              >
                {/* Export from Armenia */}
                {t("prohibitedItems.exportFromArmenia")}
              </div>
              <div
                className={classes.fromTo}
                onClick={() => {
                  setOpenBlock(2);
                }}
                style={{
                  background: openBlock === 2 ? "#13A800" : "#fff",
                  color: openBlock === 2 ? "#fff" : "#13A800",
                }}
              >
                {/* Import to Armenia */}
                {t("prohibitedItems.importToArmenia")}
              </div>
            </div>

            <div className={classes.questionsContainer}>
              <Grid container spacing={2}>
                {allData.map((data, index) => (
                  <Grid item lg={6} xs={12} key={index}>
                    <div
                      className={classes.questionBox}
                      style={{
                        border:
                          index === 0
                            ? openProhibited === index
                              ? "1px solid #FCB331"
                              : "none"
                            : openRestricted === index
                            ? "1px solid #FCB331"
                            : "none",
                      }}
                    >
                      <div className={classes.boxBlock}>
                        <span className={classes.question}>{data.title}</span>
                        <img
                          className={classes.arrowIcon}
                          style={{
                            maxHeight:
                              index === 0
                                ? openProhibited === index && isMobile
                                  ? 50
                                  : 80
                                : openRestricted === index && isMobile
                                ? 50
                                : 80,
                          }}
                          src={
                            index === 0
                              ? openProhibited === index
                                ? down
                                : right
                              : openRestricted === index
                              ? down
                              : right
                          }
                          alt="arrow icon"
                          onClick={() =>
                            index === 0
                              ? setOpenProhibited((prev) =>
                                  prev !== null ? null : index
                                )
                              : setOpenRestricted((prev) =>
                                  prev !== null ? null : index
                                )
                          }
                        />
                      </div>
                      {(openProhibited === index ||
                        openRestricted === index) && (
                        <div className={classes.openBlock}>
                          <Divider />
                          {data.info.map((each, idx) => (
                            <div className={classes.non} key={idx}>
                              {each.icon ? (
                                <img
                                  className={classes.icon}
                                  src={each.icon}
                                  alt={`${each.icon}`}
                                  style={{
                                    minHeight: each.icon === noFake && 30,
                                  }}
                                />
                              ) : (
                                " "
                              )}
                              <span style={{ paddingLeft: each.icon ? 0 : 45 }}>
                                {each.des}
                              </span>
                            </div>
                          ))}
                          <br />
                          {openBlock === 2 && openProhibited !== index && (
                            <span>
                              {t("prohibitedItems.more")}{" "}
                              <a
                                href="https://www.arlis.am/DocumentView.aspx?DocID=139281"
                                target="_blank"
                                rel="noreferrer"
                              >
                                https://www.arlis.am/DocumentView.aspx?DocID=139281
                              </a>
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
            <span className={classes.bottomSpan}>
              <span className={classes.asterisk} style={{ fontSize: 20 }}>
                *
              </span>
              {t("prohibitedItems.info")}
            </span>
          </Container>
        </div>
      </Grid>
    </Grid>
  );
};

export default ProhibitedItems;
