import React from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
const useStyeles = makeStyles((theme) => ({
  form1SenderReciver: {
    display: "flex",
    flexDirection: "column",
    marginTop: 5,
  },
  section1: {
    display: "flex",
    justifyContent: "space-betweens",
    alignItems: "center",
    padding: 10,
    height: 30,
    background: "#f7f3f2",
  },
  section1Content: {
    borderRight: "1px solid #ddd",
    marginRight: 20,
    height: "100%",
    fontSize: 15,
    display: "flex",
    alignItems: "center",
    width: "33%",
  },
  input: {
    "&:checked": {
      content: "2714",
      fontSize: "1.5em",
      color: "#fff",
    },
  },
  section2: {
    height: "auto",
    minHeight: 65,
    marginTop: 5,
    display: "flex",
    border: "1px solid #999",
  },
  totalInfo: {
    width: "10%",
    borderLeft: "1px solid #999",
    paddingBottom: 5,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  totalInfo2: {
    width: "10%",
    height: "max-content",
    borderLeft: "1px solid #999",
    paddingBottom: 5,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  totalTitle: {
    fontWeight: 600,
    fontSize: 13,
    textAlign: "center",
    whiteSpace: "pre",
  },
  totalInfos: {
    whiteSpace: "pre",
    textAlign: "center",
    fontSize: 13,
  },
  totalInfos2: {
    marginTop: 10,
    whiteSpace: "pre",
    textAlign: "center",
    fontSize: 13,
  },
  section3: { display: "flex", height: "max-content" },
}));

const Form1PackageInfo = ({ data }) => {
  const classes = useStyeles();
  return (
    <div className={classes.form1SenderReciver}>
      <div className={classes.section1}>
        <div className={classes.section1Content}>Customer declaration</div>
        <div className={classes.section1Content}>
          Item may be open officially
        </div>
        <div style={{ width: "33%", fontSize: 16 }}>
          <div>Package type</div>
          <div>
            <input
              type="checkbox"
              defaultChecked={data?.parcel_packaging === "2"}
              className={classes.input}
              readOnly
            />{" "}
            box
            <input
              type="checkbox"
              defaultChecked={data?.parcel_packaging === "1"}
              className={classes.input}
              readOnly
            />{" "}
            bag
            <input
              type="checkbox"
              className={classes.input}
              style={{ marginLeft: 10 }}
              defaultChecked={
                data?.parcel_packaging !== "1" && data?.parcel_packaging !== "2"
              }
              readOnly
            />{" "}
            other
          </div>
        </div>
      </div>
      <div className={classes.section2}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "70%",
            height: "100%",
            alignItems: "start",
          }}
        >
          <div style={{ fontSize: 16, fontWeight: 600, padding: 5 }}>
            Description of contents
          </div>
          <div style={{ padding: 5, whiteSpace: "pre", fontSize: 14 }}>
            {data?.content &&
              data.content.map((el) => el?.content_types_name + "\n")}
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>{"Quantiti \n(package)"}</div>
          <div className={classes.totalInfos}>
            {data?.content &&
              data.content.map((el) => el?.quantity + " pcs. \n")}
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>{"Net \n(weight)"}</div>
          <div className={classes.totalInfos}>
            {data?.content && data.content.map((el) => el?.weight + " kg. \n")}
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>{"Value \n(currency)"}</div>
          <div className={classes.totalInfos}>
            {data?.content && data.content.map((el) => el?.price + " € \n")}
          </div>
        </div>
      </div>
      <div className={classes.section3}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            fontSize: 11,
            marginBottom: 15,
            paddingBottom: 10,
            marginTop: 3,
            borderBottom: "2px solid #444",
            color: "#777",
            textAlign: "start",
            width: "80%",
            height: "max-content",
            alignItems: "start",
          }}
        >
          <div>
            By signing this paper I confirm that in the parcel there is no
            packaged or unpackaget tobacco excending 2 packs, drugs, narcotics,
            weapons, items off ethnic value, explosive or combustible materials
            and all the other items mentioned in terms and conditions of{" "}
            <span style={{ fontWeight: 600 }}>BLS POLAND</span>, I am
            acquaintend with the terms of terms and conditions of{" "}
            <span style={{ fontWeight: 600 }}>BLS POLAND</span> (written and/or
            verbal forms).
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 5,
              alignItems: "end",
              color: "#777",
            }}
          >
            <div>Date and sender's signature: </div>
            <div>{moment().format("YYYY - MM - DD")}</div>
            <div style={{ fontSize: 40, fontWeight: 600, marginRight: 10 }}>
              {data?.tracking_number}
            </div>
          </div>
        </div>
        <div className={classes.totalInfo2}>
          <div className={classes.totalTitle}>{"Total \ngross"}</div>
          <div className={classes.totalInfos2}>{data?.parcel_weight} kg</div>
        </div>
        <div className={classes.totalInfo2}>
          <div className={classes.totalTitle}>{"Total \nvalue"}</div>
          <div className={classes.totalInfos2}>{data?.price} €</div>
        </div>
      </div>
    </div>
  );
};

export default Form1PackageInfo;
