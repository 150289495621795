import { Button } from "@mui/material";
import ImageUploading from "react-images-uploading";
import { useStyles } from "./styles";

export function ImageUpload({
  label,
  handleFileChange,
  files,
  handleFileDelete,
  defaultPath = "/views/customers/docs/",
  disabled,
}) {
  const classes = useStyles();

  const onChange = (imageList) => {
    handleFileChange(imageList);
  };

  return (
    <div>
      <ImageUploading
        multiple
        value={files}
        onChange={onChange}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
          <div style={{ margin: "20px 0px" }}>
            <Button
              variant="outlined"
              onClick={onImageUpload}
              {...dragProps}
              className={classes.uploadImageBtn}
              disabled={disabled}
            >
              {label}
            </Button>
            <div className={classes.uploadImageWrapper}>
              {imageList.map((image, index) => {
                return (
                  <div key={index} className={classes.imageItem}>
                    <img
                      src={
                        image.doc_name
                          ? process.env.REACT_APP_API_URL +
                            defaultPath +
                            image.doc_name
                          : image["data_url"]
                      }
                      alt=""
                      height="120"
                    />
                    {!disabled && (
                      <span
                        onClick={() => {
                          onImageRemove(index);
                          handleFileDelete && handleFileDelete(image.id);
                        }}
                        className={classes.deleteBtn}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
