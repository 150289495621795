import React from "react";
import { makeStyles } from "@mui/styles";
import QRCode from "react-qr-code";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import moment from "moment";

const useStyeles = makeStyles((theme) => ({
  form1SenderReciver: {
    display: "flex",
    flexDirection: "column",
    marginTop: 5,
    width: "60%",
  },
  section1: {
    display: "flex",
    justifyContent: "space-betweens",
    alignItems: "center",
    padding: 5,
    height: 30,
    background: "#f7f3f2",
  },
  section1Content: {
    borderRight: "1px solid #ddd",
    marginRight: 10,
    height: "100%",
    fontSize: 10,
    display: "flex",
    alignItems: "center",
    width: "27%",
  },
  input: {
    height: 10,
    width: 10,
    "&:checked": {
      content: "2714",
      fontSize: "1.5em",
      color: "#fff",
    },
  },
  section2: {
    height: "auto",
    minHeight: 60,
    marginTop: 5,
    display: "flex",
    border: "1px solid #999",
  },
  totalInfo: {
    width: "19%",
    borderLeft: "1px solid #999",
    paddingBottom: 5,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  totalInfo2: {
    width: "19%",
    height: "max-content",
    borderLeft: "1px solid #999",
    paddingBottom: 5,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  totalTitle: {
    fontWeight: 600,
    fontSize: 10,
    textAlign: "center",
    whiteSpace: "pre",
  },
  totalInfos: {
    whiteSpace: "pre",
    textAlign: "center",
    fontSize: 11,
  },
  totalInfos2: {
    marginTop: 10,
    whiteSpace: "pre",
    textAlign: "center",
    fontSize: 11,
  },
  section3: { display: "flex", height: "max-content" },
}));

const Form2PackageInfo = ({ data }) => {
  const classes = useStyeles();

  return (
    <div className={classes.form1SenderReciver}>
      <div className={classes.section1}>
        <div className={classes.section1Content}>Customer declaration</div>
        <div className={classes.section1Content}>
          Item maybe opened officially
        </div>
        <div style={{ width: "46%", fontSize: 11 }}>
          <div>Package type</div>
          <div>
            <input
              type="checkbox"
              checked={data?.parcel_packaging === "1"}
              className={classes.input}
              readOnly
            />
            Box
            <input
              type="checkbox"
              checked={data?.parcel_packaging === "2"}
              className={classes.input}
              readOnly
            />
            Bag
            <input
              type="checkbox"
              className={classes.input}
              checked={
                data?.parcel_packaging !== "1" && data?.parcel_packaging !== "2"
              }
              readOnly
            />
            Other
          </div>
        </div>
      </div>
      <div className={classes.section2}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "43%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              textAlign: "start",
              height: "100%",
              width: "100%",
            }}
          >
            <div style={{ fontSize: 10, fontWeight: 600, paddingLeft: 5 }}>
              Description of contents
            </div>
            <div
              style={{
                padding: 5,
                whiteSpace: "pre",
                fontSize: 11,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {data?.content &&
                data.content.map((el) => el?.content_types_name + "\n")}
            </div>
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>{"Quantity"}</div>
          <div className={classes.totalInfos}>
            {data?.content && data.content.map((el) => "1 pcs. \n")}
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>{"Gross (kg)"}</div>
          <div className={classes.totalInfos}>
            {data?.content && data.content.map((el) => el?.weight + " kg. \n")}
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>{"Value (euro)"}</div>
          <div className={classes.totalInfos}>
            {data?.content && data.content.map((el) => el?.price + " € \n")}
          </div>
        </div>
      </div>
      <div className={classes.section3}>
        <div
          style={{
            width: "62%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 10,
              width: 77,
              marginTop: 5,
            }}
          >
            <QRCode
              value={`https://barekam.am/tracking/${data?.tracking_code}${data?.tracking_number}`}
              style={{ width: 55, height: 55, margin: "5px 0" }}
            />
          </div>
        </div>
        <div className={classes.totalInfo2}>
          <div className={classes.totalTitle}>{"Total \ngross"}</div>
          <div className={classes.totalInfos2}>{data?.parcel_weight} kg</div>
        </div>
        <div className={classes.totalInfo2}>
          <div className={classes.totalTitle}>{"Total \nvalue"}</div>
          <div className={classes.totalInfos2}>
            {data?.content.reduce((price, elem) => {
              price += +elem.price;
              return price;
            }, 0)}
            €
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form2PackageInfo;
