import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  customerSelect: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 12,
    padding: "0 30px 30px",
  },
  customerSelectLayer: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 12,
    height: 81,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 24,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 8,
    },
  },
  icon: {
    marginRight: 10,
  },
  findBtn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 10,
      fontWeight: 700,
      fontSize: 16,
      border: "none",
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.primary.main,
        border: "none",
      },
    },
  },
  label: {
    margin: "0 0 5px",
    textAlign: "left",
    fontWeight: 600,
  },
  btn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 10,
      fontWeight: 700,
      fontSize: 16,
      border: "none",
      padding: "6px 40px",
      "&:hover": {
        background: theme.palette.primary.main,
        border: "none",
      },
    },
  },
  customerRadioGroup: {
    maxHeight: 300,
    marginTop: 20,
    overflowY: "auto",
    flexWrap: "unset!important",
  },
  customerLabel: {
    padding: "5px 0",
    width: "100%",
    marginRight: "0!important",
    "& .MuiFormControlLabel-label": {
      flexGrow: 1,
    },
  },
  customerBox: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    borderBottom: "1px solid #CDCDCD",
  },
  closeIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: 20,
  },
  noData: {
    padding: "30px 0",
    fontSize: 20,
    fontWeight: 600,
  },
  phoneInput: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "12px",
    padding: "0px 15px",
    "& .PhoneInputInput": {
      border: "none",
      fontSize: 16,
      outline: "none",
      height: "1.4375em",
      padding: "7.5px 0",
    },
    "& .PhoneInput--focus": {
      border: "1px solid #13A800",
    },
    "& .PhoneInputCountrySelectArrow": {
      border: "none",
    },
  },
}));
