import axiosClient from "../utils/axiosClient";
import api from "../api";
import { formData } from "utils/helpers/formData";

const getCustomers = async (params) => {
  const response = await axiosClient.get(api.CUSTOMERS.CUSTOMERS, { params });
  return response.data;
};

const addCustomer = async (data) => {
  const response = await axiosClient.post(
    api.CUSTOMERS.CUSTOMERS,
    formData(data),
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const updateCustomer = async (data, id) => {
  const response = await axiosClient.post(
    api.CUSTOMERS.CUSTOMERS + "/" + id,
    formData(data),
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const service = {
  getCustomers,
  updateCustomer,
  addCustomer,
};

export default service;
