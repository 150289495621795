import { makeStyles } from "@mui/styles";
import imageCombination from "assets/imgCombination.png";
import divider from "assets/devider.png";
import { Container, Grid } from "@mui/material";
import SectionTitle from "components/SectionTitle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  fixedWidthContainer: {
    margin: "100px auto",
    scrollMargin: "100px",
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("md")]: {
      margin: "20px auto",
    },
  },
  main: {
    display: "flex",
    margin: "50px auto!important",
  },
  left: {
    display: "flex",
  },
  right: {
    display: "flex",
    flexDirection: "column",
  },
  rightTitle: {
    fontWeight: 700,
    fontSize: 22,
    display: "flex",
    textAlign: "left",
    maxWidth: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  subText: {
    fontWeight: 400,
    fontSize: 18,
    padding: "0px 18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  desBlock: {
    maxWidth: 600,
    textAlign: "left",
    padding: "28px 18px",
    fontSize: 18,
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      padding: "28px 0px",
    },
  },
  des: {
    paddingBottom: 8,
  },
  opportunity: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    padding: "30px 18px",
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 2,
  },
  oppSpan: {
    // paddingLeft: 12,
    paddingBottom: 10,
  },
}));

const About = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container
      maxWidth={"xl"}
      className={classes.fixedWidthContainer}
      id="about"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionTitle
            title={t("about.title")}
            subTitle={t("about.subTitle")}
          />
        </Grid>
        <Grid item xs={12} className={classes.main}>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <div className={classes.left}>
                <img
                  src={imageCombination}
                  alt="graphic"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className={classes.right}>
                <h1 className={classes.rightTitle}>{t("about.aboutTitle")}</h1>
                <div
                  style={{
                    display: "flex",
                    textAlign: "left",
                    maxWidth: 450,
                  }}
                >
                  <img src={divider} alt="divider" />
                  <div className={classes.subText}>
                    <span>{t("about.about1")}</span>
                    <br /> <br />
                    <span>{t("about.about2")}</span>
                    <br /> <br />
                    <div>{t("about.about3")}</div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
