import {
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import { makeStyles } from "@mui/styles";
import Logo from "../../assets/logo.png";
import LogoMobile from "../../assets/logoMobile.png";
import calculator from "../../assets/icons/calculator.png";
import us from "../../assets/icons/us.jpg";
import arrowDown from "../../assets/icons/Vector.png";
import { useContext, useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
// import divider from "../../assets/icons/divider.png";
import CloseIcon from "@mui/icons-material/Close";
import LoginDialog from "../dialogs/LoginDialog";
import CalculatorDialog from "../dialogs/CalculatorDialog";
import arm from "../../assets/icons/arm.png";
import poland from "../../assets/icons/poland.png";
import rus from "../../assets/icons/rus.png";
import notification from "../../assets/icons/notification.png";
import profile from "../../assets/icons/profile.png";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "context/Auth.context";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const AVAILABLE_LANGUAGES = ["hy", "pl"];

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: 100,
      display: "flex",
      background: "#FFFFFF",
      boxShadow: " 0px 6px 16px rgba(0, 0, 0, 0.06)",
      width: "100%",
      position: "fixed",
      top: 0,
      zIndex: 99,
    },
    terms: {
      display: "flex",
      height: 36,
      justifyContent: "start",
      fontWeight: 700,
      fontSize: 12,
      paddingTop: 9,
      boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.06)",
      color: "rgba(0, 0, 0, 0.6)",
      alignItems: "center",
      padding: "11px 50px",
    },
    navBarBox: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.down(1300)]: {
        justifyContent: "space-between",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    courierNavbar: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(1300)]: {
        justifyContent: "space-between",
      },
    },
    logo: {
      // marginLeft: 80,
      [theme.breakpoints.down("md")]: {
        marginLeft: 15,
      },
      [theme.breakpoints.down(1300)]: {
        order: 2,
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
    links: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
      [theme.breakpoints.down(1300)]: {
        flexDirection: "column",
        width: "90%",
        margin: 15,
      },
    },
    eachLink: {
      maxWidth: 150,
      [theme.breakpoints.down(1300)]: {
        maxWidth: "unset",
      },
    },
    spanLink: {
      fontSize: 16,
      textDecoration: "none",
      color: theme.palette.primary.contrastText,
      "&:hover": {
        cursor: "pointer",
      },
      "&:target": {
        // cursor: pointer;
        color: "red",
      },
      [theme.breakpoints.down(1300)]: {
        lineHeight: 3,
        fontWeight: 700,
      },
    },
    rightSide: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "30%",
      [theme.breakpoints.down(1300)]: {
        flexDirection: "column",
        width: "90%",
        alignItems: "start",
        marginLeft: 15,
      },
    },
    calculator: {
      minWidth: "20%",
      fontSize: 16,
      fontWeight: 700,
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.down(1300)]: {
        lineHeight: 3,
        alignItems: "center",
        display: "flex",
        // alignItems: "center",
      },
    },
    calc: {
      paddingLeft: 15,
    },
    divider: {
      "&.MuiDivider-root": {
        height: "65%",
        borderBottomWidth: 35,
      },
    },
    language: {
      minWidth: "20%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down(1300)]: {
        paddingTop: 15,
        paddingBottom: 20,
      },
    },
    langBlock: {
      display: "flex",
      padding: "15px 0px",
      fontSize: 14,
    },
    loginDiv: {
      minWidth: "25%",
      [theme.breakpoints.down(1300)]: {
        order: 3,
        minWidth: "8%",
      },
      // [theme.breakpoints.down("lg")]: {
      //   minWidth: "15%",
      // },
      [theme.breakpoints.down("md")]: {
        minWidth: "13%",
      },
    },

    chooseLang: {
      display: "flex",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuButton: {
      order: 1,
    },
    closeIcon: {
      cursor: "pointer",
      "&.MuiButtonBase-root": {
        justifyContent: "end",
        "&.MuiIconButton-root:hover": {
          background: "unset",
        },
      },
    },
    drawer: {
      "&.MuiDrawer-root": {
        top: "unset",
      },
      "& .MuiBackdrop-root": {
        inset: "unset",
      },
      "& .MuiPaper-root": {
        width: "100%",
        // minHeight: 90,
        height: "fit-content",
        top: 100,
        textAlign: "left",
      },
    },
    loginBtn: {
      "&.MuiButtonBase-root": {
        textTransform: "unset",
        background: theme.palette.primary.main,
        color: "white",
        borderRadius: 12,
        fontWeight: 400,
        fontSize: 18,
        padding: "5px 30px",
        margin: "0px 30px",
        "&:hover": {
          background: theme.palette.primary.main,
        },
        [theme.breakpoints.down(1300)]: {
          fontSize: 14,
          padding: "5px 18px",
          borderRadius: 8,
          margin: 0,
        },
      },
    },
    courierNav: {
      display: "flex",
      alignItems: "center",
      order: 3,
      // marginRight: 100,
      width: "5%",
      justifyContent: "space-between",
      [theme.breakpoints.down(1300)]: {
        flexDirection: "column",
        width: "90%",
        alignItems: "baseline",
        padding: "0px 15px 15px",
        marginRight: 0,
      },
    },
    notification: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    profile: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    mobileMenuItem: {
      display: "flex",
      alignItems: "center",
      padding: "10px 0px",
    },
    courierMenuDivider: {
      "&.MuiDivider-root": {
        width: "100%",
        borderWidth: "100%",
      },
    },
  };
});

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openCalcDialog, setOpenCalcDialog] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down(1300));
  const location = useLocation();
  let courierPage = location.pathname !== "/";
  if (location.pathname.startsWith("/tracking")) {
    courierPage = false;
  }
  const navigate = useNavigate();
  const { state: authState, dispatch } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const openDropDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onProfile = () => {
    // if (user.email !== "" && user.password !== "") {
    navigate("/courierProfile", { replace: true });
    handleProfileClose();
    // }
  };

  const onCostHistory = () => {
    navigate("/costHistory");
    handleProfileClose();
  };

  const onLogOut = () => {
    handleProfileClose();
    dispatch({
      type: "LOGOUT",
    });
  };

  const [anchorProfile, setAnchorProfile] = useState(null);
  const openProfile = Boolean(anchorProfile);

  const handleProfileClick = (event) => {
    setAnchorProfile(event.currentTarget);
  };
  const handleProfileClose = () => {
    setAnchorProfile(null);
  };

  const navList = [
    { name: /* "Services" */ t("menu.services"), nav: "#services" },
    { name: /* "Pricelist" */ t("menu.prices"), nav: "#pricelist" },
    { name: /* "About Us" */ t("menu.aboutUs"), nav: "#about" },
    // { name: /* "News" */ "Նորություններ", nav: "#news" },
    { name: /* "FAQ" */ t("menu.faq"), nav: "#faq" },
    { name: /* "Contacts" */ t("menu.contactUs"), nav: "#contacts" },
  ];

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    const onScroll = (e) => {
      // console.log(window.scrollY, location);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [location]);

  const MobileLangBlock = () =>
    !courierPage ? (
      <div className={classes.langBlock}>
        {AVAILABLE_LANGUAGES.map((lang, index) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                background: i18next.language === lang ? "#ccc" : "unset",
              }}
              onClick={() => {
                i18n.changeLanguage(lang);
                handleClose();
              }}
              key={index}
            >
              <img
                src={lang === "hy" ? arm : poland}
                alt="flag"
                style={{ margin: "0 10px", width: 30 }}
              />
            </div>
          );
        })}
      </div>
    ) : null;

  const MenuList = () => (
    <>
      <div className={classes.links}>
        {navList.map((item, idx) => (
          <div key={idx} className={classes.eachLink}>
            <Link
              smooth
              to={item.nav}
              // key={idx}
              className={classes.spanLink}
              onClick={isDrawerOpen ? handleDrawerClose : null}
              style={{
                color: location.hash === item.nav ? "#13A800" : "black",
              }}
            >
              {item.name}
            </Link>
            {isMobile && <Divider />}
          </div>
        ))}
      </div>

      <div className={classes.rightSide}>
        <div
          className={classes.calculator}
          onClick={() => setOpenCalcDialog(!openCalcDialog)}
        >
          <img src={calculator} alt="calculator Icon" />
          {isMobile && (
            <span className={classes.calc}>
              {/* Calculator */}
              {t("menu.calculator")}
            </span>
          )}
        </div>
        {!isMobile && (
          <>
            <Divider orientation="vertical" className={classes.divider} />
            <div className={classes.loginDiv}>
              <Button
                color="primary"
                className={classes.loginBtn}
                onClick={() => setOpen(!open)}
              >
                {/* Log In */}
                {t("menu.login")}
              </Button>
            </div>
            <Divider orientation="vertical" className={classes.divider} />
          </>
        )}
        {isMobile && <Divider style={{ width: "100%" }} />}
        {isMobile ? <MobileLangBlock /> : null}
      </div>
    </>
  );

  const CourierMenuList = () => (
    <div className={classes.courierNav}>
      {!isMobile && (
        <Divider orientation="vertical" className={classes.divider} />
      )}
      {/* <div className={classes.mobileMenuItem}>
        <img
          src={notification}
          alt="notification_icon"
          className={classes.notification}
        />
        {isMobile && <span>Notifications</span>}
      </div> */}
      {/* {isMobile && <Divider className={classes.courierMenuDivider} />} */}
      {/* {!isMobile && (
        <Divider orientation="vertical" className={classes.divider} />
      )} */}
      {isMobile ? <MobileLangBlock /> : null}
      <Divider />
      {isMobile && (
        <div
          onClick={handleDrawerClose}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            minHeight: "100px",
            justifyContent: "space-between",
          }}
        >
          <div onClick={onProfile}>Profile info</div>
          <Divider className={classes.courierMenuDivider} />
          <div onClick={onCostHistory}>Cost history</div>
          <Divider className={classes.courierMenuDivider} />
          <div onClick={onLogOut}>Log out</div>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className={classes.container}>
        <Container maxWidth={"xl"}>
          {/* <div className={classes.terms}>
          <span style={{ marginRight: 12 }}>Terms And Conditions</span>
          <img style={{ marginRight: 12 }} src={divider} alt="divider" />
          <span style={{ marginRight: 12 }}>FAQ</span>
          <img style={{ marginRight: 12 }} src={divider} alt="divider" />
          <span style={{ marginRight: 12 }}>Privacy Policy</span>
        </div> */}
          <div
            className={!courierPage ? classes.navBarBox : classes.courierNavbar}
          >
            <Link
              smooth
              to={!courierPage ? "#home" : "/lists"}
              className={classes.logo}
              onClick={isDrawerOpen ? handleDrawerClose : null}
            >
              <img
                src={Logo}
                alt="logo"
                // style={{ marginRight: isMobile && courierPage && 30 }}
              />
            </Link>

            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                {!courierPage ? <MenuList /> : <CourierMenuList />}

                <div style={{ marginRight: !courierPage ? 0 : 20 }}>
                  <Button
                    aria-controls={openDropDown ? "basic-menu" : undefined}
                    aria-expanded={openDropDown ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <img
                      src={
                        courierPage
                          ? us
                          : i18next.language === "hy"
                          ? arm
                          : poland
                      }
                      alt="flag"
                      style={{ margin: "0 10px", width: 30 }}
                    />
                  </Button>
                  {!courierPage && (
                    <Menu
                      disableScrollLock={true}
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openDropDown}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {AVAILABLE_LANGUAGES.map((lang, i) => {
                        if (lang !== i18next.language) {
                          return (
                            <MenuItem
                              key={i}
                              onClick={() => {
                                i18n.changeLanguage(lang);
                                handleClose();
                              }}
                            >
                              <img
                                src={lang === "hy" ? arm : poland}
                                alt="flag"
                                style={{ margin: "0 10px" }}
                              />
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                    </Menu>
                  )}
                </div>
                {courierPage && (
                  <div
                    style={{
                      order: 3,
                      // width: "20%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: 16, fontWeight: "bold" }}>
                      {authState.firstname + " " + authState.lastname}
                    </span>
                    <Button
                      id="basic-button"
                      aria-controls={openProfile ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openProfile ? "true" : undefined}
                      onClick={handleProfileClick}
                    >
                      <img
                        src={profile}
                        alt="profile"
                        className={classes.profile}
                      />
                    </Button>
                    <Menu
                      disableScrollLock={true}
                      id="basic-menu"
                      anchorEl={anchorProfile}
                      open={openProfile}
                      onClose={handleProfileClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={onProfile}>Profile info</MenuItem>
                      <MenuItem onClick={onCostHistory}>Cost history</MenuItem>
                      <MenuItem onClick={onLogOut}>Log out</MenuItem>
                    </Menu>
                  </div>
                )}
              </div>
            ) : (
              <>
                {isDrawerOpen ? (
                  <IconButton
                    color="inherit"
                    aria-label="close drawer"
                    onClick={handleDrawerClose}
                    edge="start"
                    className={classes.closeIcon}
                    size="medium"
                  >
                    <CloseIcon
                      style={{
                        color: "black",
                        padding: 18,
                      }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="end"
                    className={classes.menuButton}
                    size="medium"
                  >
                    <MenuIcon />
                  </IconButton>
                )}

                {!courierPage && (
                  <div className={classes.loginDiv}>
                    <Button
                      color="primary"
                      className={classes.loginBtn}
                      onClick={() => setOpen(!open)}
                    >
                      {/* Log In */} Մուտք
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
          <Drawer
            anchor="left"
            variant="persistent"
            open={isDrawerOpen}
            onClose={handleDrawerClose}
            className={classes.drawer}
          >
            <>
              {isMobile && (!courierPage ? <MenuList /> : <CourierMenuList />)}
            </>
          </Drawer>
        </Container>
      </div>
      <LoginDialog open={open} setOpen={setOpen} />
      <CalculatorDialog open={openCalcDialog} setOpen={setOpenCalcDialog} />
    </>
  );
};

export default NavBar;
