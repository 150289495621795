export const formData = (data) => {
  const bodyFormData = new FormData();

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (data[key] !== undefined && data[key] !== null) {
        if (Array.isArray(data[key])) {
          if (data[key].length === 0) {
            bodyFormData.append(key, "[]");
          }

          data[key].forEach((val, index) => {
            if (
              typeof val === "object" &&
              val !== null &&
              key !== "customerFile" &&
              key !== "passportFile" &&
              key !== "driversLicense" &&
              key !== "files" &&
              key !== "file"
            ) {
              for (const innerKey in val) {
                if (val[innerKey] !== undefined && val[innerKey] !== null) {
                  bodyFormData.append(
                    key + `[${index}][${innerKey}]`,
                    val[innerKey]
                  );
                }
              }
            } else {
              bodyFormData.append(key + `[${index}]`, val);
            }
          });
        } else {
          bodyFormData.append(key, data[key]);
        }
      }
    }
  }

  return bodyFormData;
};
