import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  Pagination,
  Paper,
} from "@mui/material";
import bus from "../../assets/icons/bus.png";
import delPackage from "../../assets/icons/package.png";
import SearchIcon from "@mui/icons-material/Search";
import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import imp from "../../assets/icons/import.png";
import { useStyles } from "./styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SERVICES from "services";
import SectionTitle from "components/SectionTitle";
import Parcel from "./components/Parcel";
import ParcelUnloadingDialog from "./components/ParcelUnloadingDialog";
import ConfirmDialog from "components/ConfimDialog/ConfirmDialog";
import { SettingsContext } from "context/Settings.context";
import Loading from "components/Loading";

const Parcels = () => {
  const classes = useStyles();
  const { id: listId } = useParams();
  const location = useLocation();
  const [parcels, setParcels] = useState([]);
  const [parcelsInfo, setParcelsInfo] = useState({
    totalWeight: 0,
    count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [isSenderAM, setIsSenderAM] = useState(true);
  const [listInfoLoading, setListInfoLoading] = useState(true);
  const [selectedParcelIds, setSelectedParcelIds] = useState([]);
  const [dialogState, setDialogState] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [warehouseId, setWarehouseId] = useState(null);
  const [list, setList] = useState({});
  const { dispatch: dispatchToast } = useContext(SettingsContext);
  const navigate = useNavigate();
  const componentRef = useRef({});
  let [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 4;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const state = location.state;

  const handleChange = (e, p) => {
    setCurrentPage(p);
  };

  const handleParcelSelect = (id) => {
    setSelectedParcelIds((prev) => {
      const data = [...prev];
      const index = data.indexOf(id);
      if (index > -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }

      return data;
    });
  };

  const handleSelectAll = () => {
    setSelectedParcelIds((prev) => {
      if (prev.length && prev.length === parcels.length) {
        return [];
      }
      return parcels.map((el) => el.id);
    });
  };

  const getParcels = useCallback(() => {
    setLoading(true);
    setSelectedParcelIds([]);
    if (warehouseId) {
      SERVICES.ORDERS.getWarehouseParcels(listId, warehouseId)
        .then((res) => {
          setParcels(res.data);
        })
        .finally(() => setLoading(false));
    } else {
      SERVICES.ORDERS.getParcels(listId)
        .then((res) => {
          setIsSenderAM(res.data[0]?.sender_countries_name === "Armenia");
          setParcels(res.data);
          setParcelsInfo({
            totalWeight: res.data.reduce(
              (prev, current) => prev + +current.parcel_weight,
              0
            ),
            count: res.data.length,
          });
        })
        .finally(() => setLoading(false));
    }
  }, [listId, warehouseId]);

  const getListById = useCallback(() => {
    setListInfoLoading(true);
    SERVICES.ORDERS.getOrdersById(listId)
      .then((res) => {
        setList(res.data);
      })
      .finally(() => setListInfoLoading(false));
  }, [listId]);

  const deleteParcel = useCallback(
    async (item) => {
      try {
        const response = await SERVICES.ORDERS.deleteParcel(
          listId,
          item,
          item.id
        );
        setSelectedParcelIds([]);
        getParcels();
        getListById();
        dispatchToast({
          type: "SUCCESS_TOAST",
          payload: { message: "Success action" },
        });
        return new Promise((res) => res(response));
      } catch (e) {
        if (typeof e.message === "string") {
          dispatchToast({
            type: "ERROR_TOAST",
            payload: { message: e.message },
          });
        }
        return new Promise((_, rej) => rej(e));
      }
    },
    [dispatchToast, getParcels, getListById, listId]
  );

  const handleParcelDelete = useCallback(
    (item) => {
      setConfirmDialog({
        open: true,
        title: "Delete",
        subtitle: "Are you sure you want to delete this item?",
        callback: () => deleteParcel(item),
      });
    },
    [deleteParcel]
  );

  useEffect(() => {
    getParcels();
  }, [getParcels]);

  useEffect(() => {
    getListById();
  }, [getListById]);

  const selectedParcels = parcels.filter((el) =>
    selectedParcelIds.includes(el.id)
  );

  const selectedParcelsTotalWeight = selectedParcels.reduce(
    (sum, a) => sum + +a.parcel_weight,
    0
  );

  const selectedParcelsDeliveryTotalPrice = selectedParcels.reduce(
    (sum, a) => sum + +a.price,
    0
  );

  const selectedParcelsCashTotalPrice = selectedParcels.reduce(
    (sum, a) => sum + +a.payment_cash,
    0
  );

  const selectedParcelsTransferTotalPrice = selectedParcels.reduce(
    (sum, a) => sum + +a.payment_bank_transfer,
    0
  );

  const selectedParcelsRemainderTotalPrice = selectedParcels.reduce(
    (sum, a) => sum + +a.remainder,
    0
  );

  const selectedParcelsLocalDeliveryTotalPrice = selectedParcels.reduce(
    (sum, a) => sum + +a.local_delivery_price,
    0
  );

  const totalWarehousesParcelsQuantity = list.warehouses?.reduce(
    (sum, a) => sum + +a.total_count,
    0
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionTitle title="Parcels" />
        </Grid>
        {listInfoLoading ? (
          <Loading />
        ) : (
          <>
            <Grid item xs={12} lg={5}>
              <div className={classes.blocks}>
                <span className={classes.infoTitle}>List number:</span>
                <span className={classes.infoText}>{list.list_number}</span>
              </div>
              <div className={classes.blocks}>
                <span className={classes.infoTitle}>Total weight:</span>
                <span className={classes.infoText}>{list.total_weight} kg</span>
              </div>
              <div className={classes.blocks}>
                <span className={classes.infoTitle}>Total quantity:</span>
                <span className={classes.infoText}>
                  {parcelsInfo.count + totalWarehousesParcelsQuantity} pcs
                </span>
              </div>
            </Grid>
            <Grid item xs={12} lg={7}>
              <div
                className={classes.eachBlock}
                onClick={() => setWarehouseId(null)}
                style={{
                  border:
                    warehouseId === null
                      ? "2px solid #13A800"
                      : "1px solid #A7A7A7",
                }}
              >
                <img src={bus} alt="bus" height={50} />
                <div>
                  <span className={classes.infoTitle}>Weight: </span>
                  <span className={classes.infoText}>
                    {parcelsInfo.totalWeight.toFixed(2)} kg
                  </span>
                </div>
                <div>
                  <span className={classes.infoTitle}>Quantity: </span>
                  <span className={classes.infoText}>
                    {parcelsInfo.count} pcs
                  </span>
                </div>
              </div>
              {list.warehouses?.map((el, i) => (
                <div
                  key={i}
                  className={classes.eachBlock}
                  onClick={() => setWarehouseId(el.id)}
                  style={{
                    border:
                      warehouseId === el.id
                        ? "2px solid #13A800"
                        : "1px solid #A7A7A7",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={delPackage} alt="package" height={40} />
                      <div className={classes.warehouse}>
                        <span>
                          <span className={classes.infoTitle}>Warehouse: </span>
                          <span className={classes.infoText}>
                            {el.warehouses_name}
                          </span>
                        </span>
                        <span>
                          <span className={classes.infoTitle}>Weight: </span>
                          <span className={classes.infoText}>
                            {el.total_weight}
                          </span>
                        </span>
                        <span>
                          <span className={classes.infoTitle}>Quantity: </span>
                          <span className={classes.infoText}>
                            {el.total_count} pcs
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Grid>
          </>
        )}
        {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Paper
              component="form"
              className={classes.search}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search by" />
            </Paper>
          </div> */}
        <Grid item xs={12}>
          <div className={classes.totalBlock}>
            <FormGroup>
              <FormControlLabel
                label="All"
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={
                      !!selectedParcelIds.length &&
                      selectedParcelIds.length === parcels.length
                    }
                    indeterminate={
                      !!selectedParcelIds.length &&
                      selectedParcelIds.length !== parcels.length
                    }
                    onChange={handleSelectAll}
                  />
                }
              />
            </FormGroup>
            <div className={classes.total}>
              <div className={classes.eachTotal}>
                <span>
                  Total Weight <Divider className={classes.divider} />
                </span>
                <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                  {selectedParcelsTotalWeight.toFixed(2)} kg
                </span>
              </div>
              <div className={classes.eachTotal}>
                <span>
                  Delivery Price <Divider className={classes.divider} />
                </span>
                <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                  {selectedParcelsDeliveryTotalPrice.toFixed(2)} EU
                </span>
              </div>
              <div className={classes.eachTotal}>
                <span>
                  Cash <Divider className={classes.divider} />
                </span>
                <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                  {selectedParcelsCashTotalPrice.toFixed(2)} EU
                </span>
              </div>
              <div className={classes.eachTotal}>
                <span>
                  Bank transfer <Divider className={classes.divider} />
                </span>
                <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                  {selectedParcelsTransferTotalPrice.toFixed(2)} EU
                </span>
              </div>
              <div className={classes.eachTotal}>
                <span>
                  Payment in {isSenderAM ? "EU" : "Armenia"}
                  <Divider className={classes.divider} />
                </span>
                <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                  {selectedParcelsRemainderTotalPrice.toFixed(2)} EU
                </span>
              </div>
              <div className={classes.eachTotal}>
                <span>
                  Internal delivery <Divider className={classes.divider} />
                </span>
                <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                  {selectedParcelsLocalDeliveryTotalPrice.toFixed(2)} EU
                </span>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                className={classes.btn}
                onClick={() => setDialogState(true)}
                disabled={
                  !(selectedParcelIds.length > 0) ||
                  !!warehouseId ||
                  list.status === "2"
                }
              >
                Unload
              </Button>
              {list.status !== "2" && (
                <Button
                  variant="contained"
                  className={classes.btn}
                  onClick={() => navigate(`/parcelReservation/${listId}`)}
                >
                  + Add
                </Button>
              )}
            </div>
          </div>
        </Grid>
        {loading ? (
          <Loading />
        ) : (
          <>
            {parcels
              .slice(indexOfFirstRecord, indexOfLastRecord)
              .map((parcel, idx) => (
                <Grid
                  item
                  xs={12}
                  lg={6}
                  key={parcel.id}
                  ref={(ref) => (componentRef.current[idx] = ref)}
                >
                  <Parcel
                    {...{
                      parcel,
                      handleParcelSelect,
                      state,
                      selected: selectedParcelIds.includes(parcel.id),
                      listId,
                      handleParcelDelete,
                      noEditable: !!warehouseId,
                      listStatus: list.status,
                      // reactToPrintContent: () => reactToPrintContent(idx),
                    }}
                  />
                </Grid>
              ))}
            <Grid item xs={12}>
              <Pagination
                className={classes.pagination}
                count={Math.ceil(parcels.length / recordsPerPage)}
                size="large"
                page={currentPage}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </Grid>
          </>
        )}
      </Grid>
      <ParcelUnloadingDialog
        {...{
          open: dialogState,
          onClose: () => setDialogState(false),
          selectedParcelIds,
          getParcels,
          getListById,
          emptyParcels: () => setSelectedParcelIds([]),
          listId,
        }}
      />
      <ConfirmDialog dialog={confirmDialog} setDialog={setConfirmDialog} />
    </>
  );
};

export default Parcels;
