import { useEffect, useState } from "react";
import { Grid, MenuItem, Select } from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import payment_icon from "assets/icons/payment_icon.png";
import SERVICES from "services";
import { useStyles } from "./styles";

const PaymentForm = ({
  form,
  handleChange,
  totalPayment,
  remainder,
  inputErrors,
  disabled,
  noEditable,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    SERVICES.FILTERS.getPaymentTypes().then((res) => {
      setOptions(res.data);
    });
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            <img src={payment_icon} alt="icon" className={classes.icon} />
            <span>Payment information</span>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={classes.label}>
          Price 1 kg <span className={classes.asterisk}>*</span>
        </div>
        <TextValidator
          size="small"
          fullWidth
          name="price_per_kg"
          value={form.price_per_kg}
          onChange={handleChange}
          validators={["required"]}
          errorMessages={["Required field."]}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={classes.label}>
          Total payment <span className={classes.asterisk}>*</span>
        </div>
        <TextValidator size="small" fullWidth value={totalPayment} disabled />
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={classes.label}>Payment Type (Cash)</div>
        <TextValidator
          size="small"
          fullWidth
          name="payment_cash"
          value={form.payment_cash}
          onChange={handleChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={classes.label}>Payment Type (Bank transfer)</div>
        <TextValidator
          size="small"
          fullWidth
          name="payment_bank_transfer"
          value={form.payment_bank_transfer}
          onChange={handleChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={classes.label}>Discount type</div>
        <Select
          size="small"
          fullWidth
          name="parcels_discount_type_id"
          value={form.parcels_discount_type_id}
          onChange={handleChange}
          disabled={disabled}
        >
          {options.map((el) => (
            <MenuItem key={el.id} value={el.id}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={classes.label}>Discount amount</div>
        <TextValidator
          size="small"
          fullWidth
          name="parcel_discount"
          value={form.parcel_discount}
          onChange={handleChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={classes.label}>
          Payment from recipient <span className={classes.asterisk}>*</span>
        </div>
        <TextValidator
          size="small"
          fullWidth
          name="remainder"
          value={remainder}
          onChange={handleChange}
          disabled
          helperText={inputErrors.remainder}
          error={!!inputErrors.remainder}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.label}>Comment</div>
        <TextValidator
          type="textarea"
          size="small"
          fullWidth
          name="parcel_comments"
          value={form.parcel_comments}
          onChange={handleChange}
          disabled={noEditable}
        />
      </Grid>
    </>
  );
};

export default PaymentForm;
