import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { MoreVert } from "@mui/icons-material";
import { useCallback, useEffect, useState, useContext } from "react";
import { useStyles } from "./styles";
import SectionTitle from "components/SectionTitle";
import CostDialog from "./components/CostDialog";
import SERVICES from "services";
import ConfirmDialog from "components/ConfimDialog/ConfirmDialog";
import { SettingsContext } from "context/Settings.context";

const getStatus = (value) => {
  switch (value) {
    case "0":
      return "Deleted";
    case "1":
      return "Approved";
    case "2":
      return "Unconfirmed";
    default:
      return "";
  }
};
const getColor = (value) => {
  switch (value) {
    case "0":
      return "#fc2c03";
    case "1":
      return "#359A73";
    case "2":
      return "#FCB331";
    default:
      return "";
  }
};
const getBack = (value) => {
  switch (value) {
    case "0":
      return "rgba(252, 28, 3, 0.1)";
    case "1":
      return "#F5FBF8";
    case "2":
      return "#FFFAF1";
    default:
      return "";
  }
};

const CostHistory = () => {
  const classes = useStyles();
  const [dialog, setDialog] = useState({ open: false });
  const [confrmDialog, setConfirmDialog] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(null);
  const { dispatch: dispatchToast } = useContext(SettingsContext);
  const [rowId, setRowId] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [city, setCity] = useState("");
  const [date, setDate] = useState("");

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const getCostHistory = useCallback(() => {
    SERVICES.COST.getCostHistory().then((res) => {
      setRows(res.data);
    });
  }, []);

  const deleteRow = useCallback(
    async (id) => {
      try {
        const response = await SERVICES.COST.deleteCost({ status: 0 }, id);
        getCostHistory();
        dispatchToast({
          type: "SUCCESS_TOAST",
          payload: { message: "Success action" },
        });
        return new Promise((res) => res(response));
      } catch (e) {
        if (typeof e.message === "string") {
          dispatchToast({
            type: "ERROR_TOAST",
            payload: { message: e.message },
          });
        }
        return new Promise((_, rej) => rej(e));
      }
    },
    [getCostHistory, dispatchToast]
  );

  const handleDelete = useCallback(() => {
    setConfirmDialog({
      open: true,
      title: "Delete",
      subtitle: "Are you sure you want to delete this item?",
      callback: () => deleteRow(rowId),
    });
    handleClose();
  }, [deleteRow, rowId]);

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  /* Table Pagination */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setFilter = (value) => {
    setFilteredRows(rows.filter((el) => el.list_number.includes(value)));
  };

  useEffect(() => {
    getCostHistory();
  }, [getCostHistory]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionTitle title="Cost History" />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.container}>
            <div className={classes.titleContainer}>
              <div className={classes.addBlock}>
                <Button
                  color="primary"
                  className={classes.addBtn}
                  onClick={() => setDialog({ open: true, dialogType: "add" })}
                >
                  Add cost
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Paper
              component="form"
              className={classes.search}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                disabled
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by list number"
                onChange={(event) => {
                  setFilter(event.target.value);
                }}
              />
            </Paper>
            {/* <div className={classes.selectBlock}>
              <FormControl className={classes.citiesForm}> */}
            {/* <InputLabel id="demo-simple-select-label">
                  City: {city ? city : "Select city"}
                </InputLabel> */}
            {/* <Select
                  MenuProps={{ disableScrollLock: true }}
                  displayEmpty
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={city}
                  onChange={handleCityChange}
                  renderValue={(selected) => {
                    if (selected === "") {
                      return (
                        <span className={classes.selectTitle}>
                          City:{" "}
                          <span style={{ color: "#26341F" }}> Select city</span>
                        </span>
                      );
                    }

                    return (
                      <span className={classes.selectTitle}>
                        City:{" "}
                        <span style={{ color: "#26341F" }}> {selected}</span>
                      </span>
                    );
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {[].map((city, idx) => (
                    <MenuItem value={city.label} key={idx}>
                      {city.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.citiesForm}>
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  displayEmpty
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={date}
                  onChange={handleDateChange}
                  renderValue={(selected) => {
                    if (selected === "") {
                      return (
                        <span className={classes.selectTitle}>
                          Date:{" "}
                          <span style={{ color: "#26341F" }}> Select Date</span>
                        </span>
                      );
                    }

                    return (
                      <span className={classes.selectTitle}>
                        Date:{" "}
                        <span style={{ color: "#26341F" }}> {selected}</span>
                      </span>
                    );
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {[].map((date, idx) => (
                    <MenuItem value={date} key={idx}>
                      {date}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
          </div>
          <div>
            <TableContainer component={Paper} className={classes.table}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>List number</TableCell>
                    <TableCell align="right">Type</TableCell>
                    <TableCell align="right">Price (euro)</TableCell>
                    <TableCell align="right">Car mileage (km)</TableCell>
                    <TableCell align="right">Spent fuel (litre)</TableCell>
                    <TableCell>Date added</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="right"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(filteredRows || rows)
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.list_number}</TableCell>
                        <TableCell align="right">
                          {row.expenditure_type_name}
                        </TableCell>
                        <TableCell align="center">{row.amount}</TableCell>
                        <TableCell align="center">{row.km}</TableCell>
                        <TableCell align="center">
                          {row.litres_of_fuel}
                        </TableCell>
                        <TableCell>{row.date_added}</TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              //   width: 70,
                              //   height: 32,
                              //   borderRadius: 8,
                              //   margin: "5px 0px",
                              padding: "12px 6px",
                              color: getColor(row.status),
                              background: getBack(row.status),
                              borderRadius: 8,
                            }}
                          >
                            {getStatus(row.status)}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            disabled={row.status !== "2"}
                            onClick={(event) => {
                              handleClick(event);
                              setRowId(row.id);
                            }}
                          >
                            <MoreVert
                              style={{
                                color: row.status !== "2" && "inherit",
                              }}
                            />
                          </Button>
                          <Menu
                            className={classes.menu}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setDialog({
                                  open: true,
                                  dialogType: "edit",
                                });
                                handleClose();
                              }}
                              style={{
                                fontSize: 16,
                                color: "#000000",
                              }}
                            >
                              Edit
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={handleDelete}
                              style={{ fontSize: 16, color: "#D9534F" }}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[30, 50, 100, 300, 500]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.pagination}
      />
      <ConfirmDialog dialog={confrmDialog} setDialog={setConfirmDialog} />
      <CostDialog
        {...{
          dialog,
          onClose: () => setDialog({ open: false }),
          getCostHistory,
          id: rowId,
        }}
      />
    </>
  );
};

export default CostHistory;
