import { Warning } from "@mui/icons-material";
import { Avatar, Container, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2, 1, 5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1, 1, 4),
    backgroundColor: theme.palette.error.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
    "&.MuiAvatar-root": {
      background: "red",
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    "& .MuiSvgIcon-root": {
      width: "1.5em",
      height: "1.5em",
    },
  },
}));

const WarningContainer = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="xs">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Warning />
        </Avatar>
        <Typography component="h1" variant="h5">
          Ներեցեք, ձեր այցելած էջը գոյություն չունի:
        </Typography>
      </Paper>
    </Container>
  );
};

export default WarningContainer;
