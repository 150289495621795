import {
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";

const ConfirmDialog = ({ dialog, setDialog }) => {
  const handleClose = () => {
    setDialog(null);
  };
  const [submit, setSubmit] = useState(false);
  const handleSubmit = () => {
    setSubmit(true);
    dialog?.callback &&
      dialog
        .callback()
        .then(() => handleClose())
        .finally(() => setSubmit(false));
  };
  return (
    <Dialog
      open={dialog?.open || false}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{dialog?.title}</DialogTitle>
      <DialogContentText
        style={{
          textAlign: "center",
          fontSize: 18,
          paddingTop: 10,
          paddingBottom: 20,
        }}
      >
        {dialog?.subtitle}
      </DialogContentText>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "#EF4444" }}>
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={submit}
          style={{ color: "#13A800" }}
        >
          {submit ? <CircularProgress size={20} /> : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
