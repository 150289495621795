import axiosClient from "../utils/axiosClient";
import api from "../api";

const getWarehouses = async () => {
  const response = await axiosClient.get(api.WAREHOUSE.WAREHOUSE);
  return response.data;
};

const addParcelToWarehouse = async (data, id) => {
  const response = await axiosClient.post(
    api.WAREHOUSE.WAREHOUSE + "/" + id,
    data
  );
  return response.data;
};

const service = {
  getWarehouses,
  addParcelToWarehouse,
};

export default service;
