import axiosClient from "../utils/axiosClient";
import api from "../api";
import { formData } from "utils/helpers/formData";

const getOrders = async (params) => {
  const response = await axiosClient.get(api.ORDERS.ORDERS, { params });
  return response.data;
};

const getOrdersById = async (id) => {
  const response = await axiosClient.get(api.ORDERS.ORDERS + "/" + id);
  return response.data;
};

const getParcels = async (id, parcelId) => {
  const response = await axiosClient.get(
    api.ORDERS.ORDERS + "/" + id + "/parcels" + (parcelId ? `/${parcelId}` : "")
  );
  return response.data;
};

const getWarehouseParcels = async (listId, warehouse_id) => {
  const response = await axiosClient.get(
    `${api.ORDERS.ORDERS}/${listId}/parcels`,
    { params: { warehouse_id } }
  );
  return response.data;
};

const getParcelById = async (listId, id) => {
  const response = await axiosClient.get(
    `${api.ORDERS.ORDERS}/${listId}/parcels/${id}`
  );
  return response.data;
};

const addParcel = async (data, id, parcelId) => {
  const response = await axiosClient.post(
    api.ORDERS.ORDERS + "/" + id + (parcelId ? `/${parcelId}` : ""),
    formData(data),
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const deleteParcel = async (listId, item, id) => {
  const response = await axiosClient.post(
    api.ORDERS.ORDERS + "/" + listId + "/" + id,
    { ...item, status: 0 }
  );
  return response.data;
};

const getProfile = async () => {
  const response = await axiosClient.get(api.ORDERS.PROFILE);
  return response.data;
};

const updateProfile = async (data) => {
  const response = await axiosClient.post(api.ORDERS.PROFILE, formData(data), {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const getOrderCouriers = async (id) => {
  const response = await axiosClient.get(
    api.ORDERS.ORDERS + "/" + id + "/couriers"
  );
  return response.data;
};

const service = {
  getOrders,
  getOrdersById,
  getParcels,
  addParcel,
  deleteParcel,
  getParcelById,
  getProfile,
  updateProfile,
  getOrderCouriers,
  getWarehouseParcels,
};

export default service;
