import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 12,
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: 700,
    fontSize: 40,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },
  topSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  direction: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  data: {
    fontWeight: 1000,
    fontSize: 20,
    letterSpacing: "0.4px",
    color: "#252733",
    marginRight: 10,
  },
  mainInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  eachLine: {
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
  },
  infoTitile: {
    fontSize: 16,
    letterSpacing: "0.4px",
    color: "#A7A7A7",
    margin: "10px 0px",
  },
  eye: {
    display: "flex",
    maxWidth: 30,
    maxHeight: 30,
    "&:hover": {
      cursor: "pointer",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      boxShadow: "0px 5px 12px rgba(90, 95, 105, 0.2)",
      borderRadius: 8,
    },
    "& .MuiList-root": {
      padding: 8,
    },
    "& .MuiButtonBase-root": {
      fontSize: 16,
      color: "#000000",
      fontWeight: 400,
      "&:hover": {
        cursor: "default",
        background: "#fff",
      },
    },
  },
  viewers: {
    fontSize: 16,
    letterSpacing: "0.4px",
    color: "#A7A7A7",
    paddingLeft: 14,
    marginBottom: 5,
  },
  btnSection: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,
  },
  viewBtn: {
    maxWidth: 170,
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: "#fff",
      color: "#26341F",
      borderRadius: 8,
      border: "1px solid #FCB331",
      fontWeight: 700,
      fontSize: 16,
      padding: "5px 30px",
      "&:hover": {
        background: "#fff",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "unset",
      },
    },
  },
  btn: {
    maxWidth: 170,
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 8,
      fontWeight: 700,
      fontSize: 16,
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.primary.main,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "unset",
      },
    },
  },
}));
