import AUTH from "./auth.api";
import ORDERS from "./orders.api";
import CUSTOMERS from "./customers.api";
import FILTERS from "./filters";
import ACTIONS from "./prices.api";
import WAREHOUSE from "./warehouse.api";
import COST from "./cost.api";

const APP_API = {
  AUTH,
  ORDERS,
  CUSTOMERS,
  FILTERS,
  ACTIONS,
  WAREHOUSE,
  COST,
};

export default APP_API;
