import { createContext, useReducer } from "react";

const initialState = {
  severity: "error",
  open: false,
  text: "",
};

export const SettingsContext = createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case "SUCCESS_TOAST":
      return {
        ...state,
        severity: "success",
        open: true,
        text: action.payload.message,
      };
    case "ERROR_TOAST":
      return {
        ...state,
        severity: "error",
        open: true,
        text: action.payload.message,
      };
    case "CLOSE_TOAST":
      return {
        ...state,
        open: false,
        text: "",
      };
    default:
      return state;
  }
};

const SettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
