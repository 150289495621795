import { Autocomplete, Button, Dialog, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useState, useEffect, useContext } from "react";
import SERVICES from "services";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { SettingsContext } from "context/Settings.context";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    flexDirection: "column",
    background: "transparent",
    "& .MuiPaper-root": {
      borderRadius: 12,
      width: "50%",
      [theme.breakpoints.down("md")]: {
        width: "70%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiTypography-root": {
      padding: 0,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    "&.MuiDialogContent-root": {
      padding: "45px 40px",
      [theme.breakpoints.down("lg")]: {
        padding: 20,
      },
    },
  },
  titleBlock: {
    paddingBottom: 40,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      alignItems: "baseline",
      paddingBottom: 20,
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontWeight: 700,
    fontSize: 36,
    textAlign: "center",
    lineHeight: 2,
    paddingLeft: 25,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "start",
      paddingLeft: 0,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  closeAndBackBtn: {
    border: "2px solid",
    borderRadius: "50%",
    cursor: "pointer",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 24,
    letterSpacing: "0.4px",
    textAlign: "center",
    paddingBottom: 35,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("lg")]: {
      textAlign: "start",
    },
  },
  inputs: {
    display: "flex",
    flexDirection: "column",
  },
  inputTitle: {
    fontWeight: 1000,
    lineHeight: 2,
    color: "#252733",
    fontSize: 16,
    letterSpacing: "0.4px",
  },
  autocomplete: {
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
  },
  phoneInput: {
    height: "50px",
    // marginBottom: 25,
    // border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "12px",
    padding: "0px 15px",
    "& .PhoneInputInput": {
      border: "none",
      height: 40,
      fontSize: 16,
      outline: "none",
    },
    "& .PhoneInput--focus": {
      border: "1px solid #13A800",
    },
    "& .PhoneInputCountrySelectArrow": {
      border: "none",
    },
  },
  postIndex: {
    "&.MuiFormControl-root": {
      marginBottom: 35,
    },
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiFormLabel-asterisk": {
      color: "#FF0B21",
      fontSize: 18,
      fontWeight: 700,
    },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   borderColor: theme.palette.primary.main,
    // },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  required: {
    fontWeight: 700,
    fontSize: 14,
    marginTop: 30,
    letterSpacing: "0.4px",
  },
  asterisk: {
    fontWeight: 700,
    marginRight: 10,
    letterSpacing: "0.4px",
    color: "#FF0B21",
  },
  btnSection: {
    display: "flex",
    justifyContent: "center",
  },
  example: {
    fontSize: 12,
  },
  nextBtn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      marginTop: 70,
      borderRadius: 12,
      fontWeight: 700,
      fontSize: 22,
      padding: "5px 30px",
      width: "30%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  },
}));

const initialForm = {
  country: null,
  city: null,
  postal_code: "",
  firstname: "",
  lastname: "",
  telephone: undefined,
  parcel_quantity: "",
  parcel_weight: "",
  description: "",
};

const AddOrderDialog = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [dialogForm, setDialogForm] = useState(initialForm);
  const { dispatch } = useContext(SettingsContext);
  const [phoneCountries, setPhoneCountries] = useState([]);
  const [id, setId] = useState(1);
  const [options, setOptions] = useState([]);
  const [formError, setFormError] = useState({
    telephone: "",
  });

  const handleClose = () => {
    setOpen(false);
    setDialogForm(initialForm);
    setId(1);
  };

  const handleFormChange = (val, name) => {
    setDialogForm((data) => {
      return {
        ...data,
        ...{
          [name]: val,
        },
      };
    });
  };

  const handleSubmit = () => {
    if (id === 1) {
      setId((count) => count + 1);
    } else if (id === 2) {
      if (!!dialogForm.telephone && dialogForm?.telephone?.length > 6) {
        setId((count) => count + 1);
      } else {
        setFormError((prev) => ({ ...prev, telephone: "Required field" }));
      }
    } else {
      const data = dialogForm;
      // if (!data?.description) {
      //   delete data.description;
      // }
      SERVICES.ACTIONS.createOrder(data)
        .then((res) => {
          dispatch({
            type: "SUCCESS_TOAST",
            payload: { message: "You have successfully created your order" },
          });
          handleClose();
        })
        .catch((err) => {
          if (typeof err.message === "string") {
            dispatch({
              type: "ERROR_TOAST",
              payload: { message: err.message },
            });
          }
        });
    }
  };

  useEffect(() => {
    SERVICES.FILTERS.getCountries().then((res) => {
      setOptions(res.data);
      setPhoneCountries(res.data.map((el) => el.code));
    });
  }, []);

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogContent className={classes.content}>
        <div className={classes.titleBlock}>
          {id !== 1 && (
            <ArrowBackIosNewRoundedIcon
              onClick={() => setId((count) => count - 1)}
              className={classes.closeAndBackBtn}
            />
          )}
          <div className={classes.title}>{t("addOrderDialog.title")}</div>
          <CloseIcon
            onClick={handleClose}
            className={classes.closeAndBackBtn}
          />
        </div>
        <div className={classes.subTitle}>
          {id === 1 || id === 2
            ? t("addOrderDialog.personalInfo")
            : t("addOrderDialog.parcel")}
        </div>
        <ValidatorForm
          onSubmit={handleSubmit}
          onError={(errors) => console.log(errors)}
        >
          {id === 1 ? (
            <div className={classes.inputs}>
              <span className={classes.inputTitle}>
                {t("addOrderDialog.country")}{" "}
                <span className={classes.asterisk}>*</span>
              </span>
              <Autocomplete
                value={dialogForm.country || null}
                onChange={(event, option) => {
                  handleFormChange(option.id || null, "country");
                }}
                options={options}
                className={classes.autocomplete}
                disablePortal
                getOptionLabel={(option) => {
                  if (option === null) return option;

                  if (typeof option === "object") {
                    return option.name;
                  }
                  const opt = options?.find((el) => el.id === option);
                  return opt?.name || "";
                }}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value;
                }}
                noOptionsText="No data"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label=""
                    value={dialogForm.country || null}
                    className={classes.postIndex}
                    // inputProps={{
                    //   ...params.inputProps,
                    //   autocomplete: "nope",
                    // }}
                    validators={["required"]}
                    errorMessages={["Required field."]}
                  />
                )}
              />
              <span className={classes.inputTitle}>
                {t("addOrderDialog.city")}{" "}
                <span className={classes.asterisk}>*</span>
              </span>
              <TextValidator
                label=""
                value={dialogForm.city || ""}
                className={classes.postIndex}
                fullWidth
                name="city"
                inputProps={{
                  autoComplete: "nope",
                }}
                onChange={(event) =>
                  handleFormChange(event.target.value, "city")
                }
                validators={["required"]}
                errorMessages={["Required field."]}
              />
              <span className={classes.inputTitle}>
                {t("addOrderDialog.post")}{" "}
                <span className={classes.asterisk}>*</span>
              </span>
              <TextValidator
                label=""
                value={dialogForm.postal_code || ""}
                className={classes.postIndex}
                fullWidth
                inputProps={{
                  autoComplete: "nope",
                }}
                name="postal_code"
                onChange={(event) =>
                  handleFormChange(event.target.value, "postal_code")
                }
                validators={["required"]}
                errorMessages={["Required field."]}
              />
            </div>
          ) : id === 2 ? (
            <div className={classes.inputs}>
              <span className={classes.inputTitle}>
                {t("addOrderDialog.name")}{" "}
                <span className={classes.asterisk}>*</span>
              </span>
              <TextValidator
                label=""
                value={dialogForm.firstname || ""}
                className={classes.postIndex}
                fullWidth
                name="firstname"
                onChange={(event) =>
                  handleFormChange(event.target.value, "firstname")
                }
                validators={["required"]}
                errorMessages={["Required field."]}
              />
              <span className={classes.inputTitle}>
                {t("addOrderDialog.lastname")}{" "}
                <span className={classes.asterisk}>*</span>
              </span>
              <TextValidator
                label=""
                value={dialogForm.lastname || ""}
                className={classes.postIndex}
                fullWidth
                name="lastname"
                onChange={(event) =>
                  handleFormChange(event.target.value, "lastname")
                }
                validators={["required"]}
                errorMessages={["Required field."]}
              />
              {!!phoneCountries.length && (
                <>
                  <span className={classes.inputTitle}>
                    {t("addOrderDialog.phone")}{" "}
                    <span className={classes.asterisk}>*</span>
                  </span>
                  <PhoneInput
                    className={classes.phoneInput}
                    style={{
                      border: formError.telephone
                        ? "1px solid #d32f2f"
                        : "1px solid rgba(0, 0, 0, 0.23)",
                    }}
                    international
                    placeholder="Phone"
                    value={dialogForm.telephone}
                    countries={phoneCountries}
                    onChange={(val) => {
                      if (val)
                        setFormError((prev) => ({ ...prev, telephone: "" }));
                      handleFormChange(val, "telephone");
                    }}
                  />
                </>
              )}

              {formError.telephone && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: 13,
                    marginTop: 5,
                    marginLeft: 15,
                    fontWeight: 800,
                  }}
                >
                  {formError.telephone}
                </span>
              )}
            </div>
          ) : (
            <div className={classes.inputs}>
              <span className={classes.inputTitle}>
                {t("addOrderDialog.parcelsCount")}{" "}
                <span className={classes.asterisk}>*</span>
              </span>
              <TextValidator
                label=""
                value={dialogForm.parcel_quantity || ""}
                className={classes.postIndex}
                fullWidth
                name="parcel_quantity"
                onChange={(event) =>
                  !isNaN(Number(event.target.value)) &&
                  handleFormChange(event.target.value, "parcel_quantity")
                }
                validators={["required"]}
                errorMessages={["Required field."]}
              />

              <span className={classes.inputTitle}>
                {t("addOrderDialog.parcelWeight")}{" "}
                <span className={classes.asterisk}>*</span>
              </span>
              <TextValidator
                label=""
                value={dialogForm.parcel_weight || ""}
                className={classes.postIndex}
                fullWidth
                name="parcel_weight"
                onChange={(event) =>
                  !isNaN(Number(event.target.value)) &&
                  handleFormChange(event.target.value, "parcel_weight")
                }
                validators={["required"]}
                errorMessages={["Required field."]}
              />

              <span className={classes.inputTitle}>
                {t("addOrderDialog.specialNotes")}{" "}
              </span>
              <TextValidator
                label=""
                value={dialogForm.description || ""}
                className={classes.postIndex}
                fullWidth
                inputProps={{
                  autoComplete: "nope",
                }}
                onChange={(event) =>
                  handleFormChange(event.target.value, "description")
                }
                style={{ marginBottom: 10 }}
              />
              <span className={classes.example}>
                {t("addOrderDialog.exampleNote")}
              </span>
            </div>
          )}
          <div className={classes.required}>
            <span className={classes.asterisk}>*</span>
            {t("global.required")}
          </div>
          <div className={classes.btnSection}>
            <Button
              variant="contained"
              className={classes.nextBtn}
              type="submit"
            >
              {id !== 3
                ? t("addOrderDialog.next")
                : t("addOrderDialog.confirm")}
            </Button>
          </div>
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  );
};

export default AddOrderDialog;
