import { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import "react-phone-number-input/style.css";
import SERVICES from "services";
import { SettingsContext } from "context/Settings.context";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

const useStyles = makeStyles((theme) => ({
  unloadingParcels: {
    "&  .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& .MuiSelect-select": {
      textAlign: "left",
    },
  },
  label: {
    margin: "15px 0 5px",
    textAlign: "left",
    fontWeight: 600,
  },
  asterisk: {
    fontWeight: 700,
    letterSpacing: "0.4px",
    color: "#EF4444",
  },
}));

const ParcelUnloadingDialog = ({
  open,
  onClose,
  selectedParcelIds,
  getParcels,
  getListById,
  emptyParcels,
  listId,
}) => {
  const classes = useStyles();
  const [warehouses, setWarehouses] = useState([]);
  const [value, setValue] = useState("");
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { dispatch: dispatchToast } = useContext(SettingsContext);

  const handleClose = () => {
    setValue("");
    onClose();
  };

  const handleSubmit = () => {
    setIsSubmiting(true);
    SERVICES.WAREHOUSE.addParcelToWarehouse(
      { order_list_id: listId, parcelIDs: selectedParcelIds },
      value
    )
      .then(() => {
        getParcels();
        getListById();
        emptyParcels();
        handleClose();
        dispatchToast({
          type: "SUCCESS_TOAST",
          payload: { message: "Success action" },
        });
      })
      .catch((err) => {
        if (typeof err.message === "string") {
          dispatchToast({
            type: "ERROR_TOAST",
            payload: { message: err.message },
          });
        }
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  useEffect(() => {
    SERVICES.WAREHOUSE.getWarehouses().then((res) => {
      setWarehouses(res.data);
    });
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      className={classes.unloadingParcels}
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <DialogTitle>Unloading at the warehouse</DialogTitle>
        <DialogContent>
          <div className={classes.label}>
            Warehouse <span className={classes.asterisk}>*</span>
          </div>
          <TextValidator
            select
            size="small"
            fullWidth
            value={value}
            onChange={(event) => setValue(event.target.value)}
            validators={["required"]}
            errorMessages={["Required field."]}
          >
            {warehouses.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </TextValidator>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#EF4444" }}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSubmiting}
            style={{ color: "#13A800" }}
          >
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

export default ParcelUnloadingDialog;
