import React from "react";
import { makeStyles } from "@mui/styles";
import Form2PackageInfo from "./Form2PackageInfo";
const useStyeles = makeStyles((theme) => ({
  form1Sender: {
    width: "35%",
    textAlign: "left",
  },
  form1BarCode: {
    width: "15%",
    margin: "0 10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "start",
  },
  centerTitle: { fontSize: 11, fontWeight: 500, marginBottom: 1 },
  centerInfo: { fontSize: 12, fontWeight: 500, marginBottom: 2 },
  form1SenderReciver: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 900,
    textAlign: "left",
    marginBottom: 5,
    marginTop: 8,
  },
  info: {
    borderBottom: "1px solid #eee",
    fontSize: 12,
    fontWeight: 400,
    height: "max-content",
    paddingBottom: 2,
    display: "flex",
    alignItems: "end",
  },
}));

const SenderRecieverForm2 = ({ data }) => {
  const classes = useStyeles();
  const senderRecieverKeys = [
    {
      key: "director_firstname",
      secondKey: "director_lastname",
      title: "Name",
    },
    { key: "customer_address", title: "Address" },
    {
      key: "postal_code",
      title: "Post index",
      customStyle: { fontWeight: 400, fontSize: 12 },
    },
    {
      key: "city",
      secondKey: "states_name",
      thirdKey: "country_name",
      title: "City/Region/Country",
      customStyle: { fontWeight: 400, fontSize: 12 },
    },
    {
      key: "email",
      title: "Email",
      customStyle: { fontWeight: 400, fontSize: 12 },
    },
    { key: "telephone", title: "Phone" },
  ];

  return (
    <div className={classes.form1SenderReciver}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <div className={classes.form1Sender}>
          <div className={classes.sectionTitle}>Sender</div>
          {senderRecieverKeys.map((el, idx) => (
            <div
              key={idx}
              className={classes.info}
              style={el.customStyle && el.customStyle}
            >
              {el.title}: {""}
              {el.key && data?.sender[el.key]}
              {el.secondKey &&
                (el.secondKey === "states_name" ? "/" : " ") +
                  (el.secondKey === "states_name"
                    ? data?.sender.country_code === "AM"
                      ? data?.sender[el.secondKey]
                      : data?.sender["other_states_name"]
                    : data?.sender[el.secondKey])}
              {el.thirdKey && "/" + data?.sender[el.thirdKey]}
            </div>
          ))}
          {data?.sender.taxpayer_id === "1" && (
            <div className={classes.info}>
              Company: {data?.sender.company_name}
            </div>
          )}
          <div className={classes.sectionTitle}>Reciever</div>
          {senderRecieverKeys.map((el, idx) => (
            <div
              item
              key={idx}
              className={classes.info}
              style={el.customStyle && el.customStyle}
            >
              {el.title}: {""}
              {el.key && data?.receiver[el.key]}
              {el.secondKey &&
                (el.secondKey === "states_name" ? "/" : " ") +
                  (el.secondKey === "states_name"
                    ? data?.receiver.country_code === "AM"
                      ? data?.receiver[el.secondKey]
                      : data?.receiver["other_states_name"]
                    : data?.receiver[el.secondKey])}
              {el.thirdKey && "/" + data?.receiver[el.thirdKey]}
            </div>
          ))}
          {data?.receiver.taxpayer_id === "1" && (
            <div className={classes.info}>
              Company: {data?.receiver.company_name}
            </div>
          )}
        </div>
        <div className={classes.form1BarCode}>
          <div className={classes.centerTitle}>Total Price</div>
          <div className={classes.centerInfo}>
            {data?.parcel_discount
              ? +data?.price -
                +data?.local_delivery_price +
                +data?.parcel_discount
              : +data?.price - +data?.local_delivery_price}{" "}
            €{" "}
            {data?.dimension_calculation === "1" ? (
              <span style={{ opacity: 0.6 }}>v</span>
            ) : null}
          </div>
          {data?.local_delivery_price && +data?.local_delivery_price > 0 && (
            <>
              <div className={classes.centerTitle}>Internal delivery</div>
              <div className={classes.centerInfo}>
                {data?.local_delivery_price} €
              </div>
            </>
          )}
          {data?.parcel_discount && data?.parcel_discount !== "0" ? (
            <>
              <div className={classes.centerTitle}>Discount</div>
              <div className={classes.centerInfo}>
                {data?.parcel_discount} €
              </div>
            </>
          ) : null}
          {data?.local_delivery_price && +data?.local_delivery_price > 0 && (
            <>
              <div className={classes.centerTitle}>To pay</div>
              <div className={classes.centerInfo}>{data?.price} €</div>
            </>
          )}
          {data?.payment_cash && data?.payment_cash > 0 && (
            <>
              <div className={classes.centerTitle}>Cash paid</div>
              <div className={classes.centerInfo}>{data?.payment_cash} €</div>
            </>
          )}
          {data?.payment_bank_transfer && data?.payment_bank_transfer > 0 && (
            <>
              <div className={classes.centerTitle}>Bank transfer</div>
              <div className={classes.centerInfo}>
                {data?.payment_bank_transfer} €
              </div>
            </>
          )}
          {data?.remainder && data?.remainder > 0 && (
            <>
              <div className={classes.centerTitle}>
                Payment in{" "}
                {data?.sender.country_code === "AM" ? "EU" : "Armenia"}
              </div>
              <div className={classes.centerInfo}>{data?.remainder} €</div>
            </>
          )}
        </div>
        <Form2PackageInfo data={data} />
      </div>
    </div>
  );
};

export default SenderRecieverForm2;
