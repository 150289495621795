import { Dialog, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import bus from "assets/icons/deliveryBus.png";
import time from "assets/icons/deliveryTime.png";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import moment from "moment";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    flexDirection: "column",
    background: "transparent",
    color: theme.palette.primary.contrastText,
    "& .MuiPaper-root": {
      borderRadius: 12,
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiTypography-root": {
      padding: 0,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    "&.MuiDialogContent-root": {
      padding: "45px 40px",
      [theme.breakpoints.down("lg")]: {
        padding: 20,
      },
    },
  },
  titleBlock: {
    paddingBottom: 40,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      alignItems: "baseline",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontWeight: 700,
    fontSize: 36,
    textAlign: "center",
    lineHeight: 2,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "start",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  closeBtn: {
    border: "2px solid",
    borderRadius: "50%",
    cursor: "pointer",
  },
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  gridContainer: {
    justifyContent: "space-evenly",

    "& .MuiGrid-root": {
      [theme.breakpoints.down("lg")]: {
        maxWidth: "unset",
      },
    },
  },
  box: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: 12,
    margin: "20px 15px",
    filter: "drop-shadow(0px 24px 64px rgba(0, 0, 0, 0.04))",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      margin: "20px 0px",
    },
  },
  busIcon: {
    background: "linear-gradient(134.85deg, #A3FF97 1.75%, #93E689 100%)",
    borderRadius: "12px 0px 0px 12px",
    padding: "36px 44px",
    [theme.breakpoints.down("sm")]: {
      padding: "36px 28px",
    },
  },
  timeIcon: {
    background: "linear-gradient(135deg, #FFBF4E 0%, #FED182 100%)",
    borderRadius: "12px 0px 0px 12px",
    padding: "36px 44px",
    [theme.breakpoints.down("sm")]: {
      padding: "36px 28px",
    },
  },
  textBlock: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 20px",
  },
  infoTitle: {
    fontWeight: 400,
    fontSize: 16,
    color: "rgba(38, 52, 31, 0.8)",
  },
  infoTitle1: {
    fontWeight: 400,
    fontSize: 16,
    color: "rgba(38, 52, 31, 0.8)",
    [theme.breakpoints.down(440)]: {
      fontSize: 13,
    },
  },
  info: {
    fontWeight: 700,
    fontSize: 22,
    wordBreak: "break-word",

    lineHeight: 2,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  info1: {
    fontWeight: 700,
    fontSize: 22,
    wordBreak: "break-word",
    width: "max-content",
    lineHeight: 2,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(440)]: {
      fontSize: 16,
    },
  },
  detailsBlock: {
    display: "flex",
    justifyContent: "space-between",
    padding: "80px 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "0px 10px 45px 10px",
    },
  },
  each: {
    display: "flex",
    flexDirection: "column",
  },
  detailDesc: {
    fontWeight: 700,
    fontSize: 16,
  },
  date: {
    fontWeight: 400,
    fontSize: 12,
  },
  bar: {
    width: "100%",
    textOrientation: "vertical",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      width: "unset",
    },
  },
  stepper: {
    "& .MuiSvgIcon-root": {
      color: "#13A800!important",
      height: "30px!important",
      width: "30px!important",
    },
    "& .MuiStepLabel-label": {
      fontSize: "17px!important",
    },
  },
  dates: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
}));

const OrderDetailsDialog = ({ open, setOpen, trackInfo }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    setOpen({ trackNumber: "", trackStatuses: [], trackStatus: {} });
  };

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogContent className={classes.content}>
        <div className={classes.titleBlock}>
          <div className={classes.title}>{t("trackDialog.title")}</div>
          <CloseIcon onClick={handleClose} className={classes.closeBtn} />
        </div>
        <div className={classes.boxContainer}>
          {/* <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item sm={12} lg={6}> */}
          <div className={classes.box}>
            <img src={bus} alt="bus" className={classes.busIcon} />
            <div className={classes.textBlock}>
              <span className={classes.infoTitle}>
                {t("trackDialog.trackNumber")}
              </span>
              <span className={classes.info}>{trackInfo.trackNumber} </span>
            </div>
          </div>
          {/* </Grid>
            <Grid item sm={12} lg={6}> */}
          <div className={classes.box}>
            <img src={time} alt="time" className={classes.timeIcon} />
            <div className={classes.dates}>
              <div className={classes.textBlock}>
                <span className={classes.infoTitle1}>
                  {t("trackDialog.admissionDate")}
                </span>
                <span className={classes.info1}>
                  {trackInfo.trackStatus?.length
                    ? moment(trackInfo.trackStatus[0]?.date_added).format(
                        "DD-MM-YYYY"
                      )
                    : "- - -"}
                </span>
              </div>
              <div className={classes.textBlock}>
                <span className={classes.infoTitle1}>
                  {t("trackDialog.estimatedDeliveryDate")}
                </span>
                <span className={classes.info1}>
                  {trackInfo.trackStatus?.length
                    ? moment(trackInfo.trackStatus[0]?.date_added)
                        .add("30", "days")
                        .format("DD-MM-YYYY")
                    : "- - -"}
                </span>
              </div>
            </div>
          </div>
          {/* </Grid>
          </Grid> */}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {trackInfo?.trackStatuses?.length &&
            trackInfo?.trackStatus?.length && (
              <Box>
                <Stepper
                  className={classes.stepper}
                  activeStep={trackInfo?.trackStatus.length}
                  orientation="vertical"
                >
                  {trackInfo.trackStatus
                    // .slice(0, Number(trackInfo.trackStatus.id))
                    .map((el) => (
                      <Step key={el.id} style={{ position: "relative" }}>
                        <StepLabel>{el?.name}</StepLabel>
                        <span
                          style={{
                            marginLeft: 40,
                          }}
                        >
                          {moment(el?.date_added).format("DD-MM-YYYY HH:mm:ss")}
                        </span>
                      </Step>
                    ))}
                </Stepper>
              </Box>
            )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OrderDetailsDialog;
